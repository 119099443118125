export default {
  en: 'English',
  zh: '中文',
  fr: 'Français',
  popupBanner: { doNotShowThisAgain: 'Do Not Show This Again.' },
  common: {
    search: 'Search',
    liveChat: { desc: 'Need Help ? Live Chat' },
    country: { international: 'International', australia: 'Australia' },
    verification: { toSlide: 'Slide to complete the puzzle' },
    field: {
      nameOnCard: 'Name on card',
      cardholderName: "Cardholder's Name",
      first4Digits: 'First 4 Digits on Card',
      last3Digits: 'Last 3 Digits on Card',
      first6Digits: 'First 6 Digits on Card',
      last4Digits: 'Last 4 Digits on Card',
      bankName: 'Bank Name',
      cardNumber: 'Card Number',
      bankAddress: 'Bank Address',
      branchAddress: 'Branch Address',
      bankBranch: 'Bank Branch',
      bankCity: 'Bank City',
      bankProvince: 'Bank Province',
      bankBeneficiaryName: 'Bank Beneficiary Name',
      bankBeneAccName: 'Bank Beneficiary Account Name',
      bankBeneAccNum: 'Bank Beneficiary Account Number',
      bankBeneAddress: 'Beneficiary Address',
      bankAccNum: 'Bank Account Number',
      bankAccName: 'Bank Account Name',
      bankHolderAddress: 'Account Holder’s Address',
      address: 'Address',
      accName: 'Account Name',
      accNum: 'Account Number',
      accNumTo: 'Account Number',
      bsbNum: 'BSB Number',
      bankIbanNum: 'Beneficiary IBAN number',
      swift: 'SWIFT code',
      bankSwiftCode: 'Bank Swift code',
      bankSortCode: 'Bank Sort code',
      ifscCode: 'IFSC code',
      branch: 'Branch',
      swiftNonAUD: 'SWIFT code (Non AUD Accounts)',
      amt: 'Amount',
      amtIn: 'Amount In',
      amtUSD: 'Amount(USD)',
      availableAmount: 'Available Amount',
      transitNumber: 'Transit Number',
      institutionNumber: 'Institution Number',
      imptNotes: 'Important notes',
      yes: 'Yes',
      no: 'No',
      payMethod: 'Payment Method:',
      expDate: 'Expiration Date',
      bsb: 'BSB',
      abaSortCode: 'ABA/Sort Code:',
      country_region: 'Country / Region',
      currLeverage: 'Current Leverage',
      accEquity: 'Account Equity',
      newLeverage: 'Desired Leverage',
      currPw: 'Current Password',
      newPw: 'New Password',
      mt4Account: 'Mt4 Account',
      confirmPw: 'Confirm New Password',
      loginEmail: 'Login Email',
      email: 'Email',
      mobileNumber: 'Mobile Number',
      pw: 'Password',
      accType: 'Account Type',
      enterCode: 'Enter Code',
      name: 'Name',
      emailAdd: 'Email Address',
      phone: 'Phone Number',
      dob: 'Date of Birth',
      nat: 'Nationality',
      country: 'Country of residency',
      street: 'Street number/Street Name',
      suburbOrCity: 'Suburb/City',
      provinceOrState: 'Province/State',
      postcode: 'Postcode',
      empStat: 'Employment Status',
      annIncome: 'Annual Income',
      save: 'Investment',
      source: 'Source of Income',
      industry: 'Industry',
      statusDef: 'STATUS DEFINITIONS',
      month: 'Month',
      year: 'Year',
      select: 'Select',
      completed: 'Completed',
      incomplete: 'Incomplete',
      submitted: 'Submitted',
      successful: 'Successful',
      processing: 'Processing',
      cancelled: 'Cancelled',
      failed: 'Failed',
      rejected: 'Rejected',
      upload: 'Upload',
      pending: 'Pending',
      partialsuccess: 'Partial Success',
      ccNum: 'Credit Card Number',
      ccExpireDate: 'Expiry Date',
      bankCardNumber: 'Bank Card Number',
      cardHolderName: 'Card Holder Name',
      reservedMobileNumber: 'Mobile Phone Number for Bank',
      nationalId: 'National ID Card',
      cardPhoto: 'Card Photo(Front)',
      notes: 'Notes',
      bankAccOrIBAN: 'Bank Account Number/IBAN',
      selectWithdrawCard: 'Select Your Withdraw Card',
      updateCardPhoto: 'please upload your card photo(Front)',
      actualAmount: 'Actual Amount',
      resetBalTo: 'Reset Balance to',
      ipChangeWarning:
        'Get an alert email when anyone logs into your account from a different location',
      enterVerificationCode: 'Enter Verification Code',
      alias: 'Cliq Alias',
      IBAN: 'IBAN',
      noEligibleAcc: 'no eligible accounts',
      accDigit: 'Account Digit',
      docID: 'Document ID',
      ebuyAccName: 'EBuy Account Name',
      ebuyEmailAddress: 'EBuy Email Address',
      skrillAccName: 'Skrill Account Name',
      skrillEmailAddress: 'Skrill Email Address',
      netellerAccName: 'Neteller Account Name',
      netellerEmailAddress: 'Neteller Email Address',
      paymentAccName: '{name} Account Name',
      paymentAccNum: '{name} Account Number',
      documentType: 'Document Type',
      bankBranchCode: 'Branch Code',
      applePay: 'Apple Pay',
      bankAccNumKate: 'Bank Account Name in Katakana',
      beneficiaryAccountName: 'Beneficiary Account Name',
      beneficiaryBankSwiftCode: 'Beneficiary Bank Swift Code',
      beneficiaryAccountNumber: 'Beneficiary Account Number',
      beneficiaryBankBsbNumber: 'Beneficiary Bank BSB Number',
      beneficiaryBankName: 'Beneficiary Bank Name',
      BeneficiaryBankAddress: 'Beneficiary Bank Address',
      BeneficiaryBankSortCode: 'Beneficiary Bank Sort Code',
      paymentReference: 'Payment Reference:**',
      paymentReferenceContext:
        'Please include {accountNumber} in the bank transfer reference field.',
      reversedfailed: 'Reversed Failed',
      beneficiaryName: 'Beneficiary Name',
      supermarket: 'Supermarket',
      taiwanBankCode3digits: 'Taiwan Bank Code (3 digits )',
      english: 'English',
      francais: 'Francais',
      espanol: 'Espanol',
      portuguese: 'Portuguese',
      korean: 'Korean',
      chinese: 'Simplified Chinese',
      japanese: 'Japanese',
      Thai: 'Thai',
      deutsch: 'Deutsch',
      indonesia: 'Indonesian',
      vietnamese: 'Vietnamese',
      arabic: 'Arabic',
    },
    column: {
      date: 'DATE',
      type: 'TYPE',
      server: 'SERVER',
      status: 'STATUS',
      comment: 'COMMENT',
      accNum: 'ACCOUNT NUMBER',
      currency: 'CURRENCY',
      equity: 'EQUITY',
      credits: 'CREDITS',
      balance: 'BALANCE',
      leverage: 'LEVERAGE',
      resetMT4PW: 'RESET MT4 PASSWORD',
      resetMT5PW: 'RESET MT5 PASSWORD',
      tradingAcc: 'TRADING ACCOUNT',
      method: 'METHOD',
      amt: 'AMOUNT',
      procNote: 'PROCCESS NOTES',
      number: 'CARD NUMBER',
      PLATFORM: 'PLATFORM',
      resetBal: 'RESET BALANCE',
      remark: 'REMARK',
    },
    key: { from: 'FROM', to: 'TO' },
    fund: {
      must: 'MUST',
      infoHover: 'The name on your card {must} match the name on your trading account',
      ccUploadReminder:
        'Please upload a photo of the front of your card for verification. (Please cover the middle 6 digits)',
    },
    button: {
      iKnow: 'I understand',
      bkToHm: 'Back To Home Page',
      smsLogin: 'MOBILE LOGIN',
      goToIB: 'Switch To IB Portal',
      logout: 'LOGOUT',
      submit: 'Submit',
      selectFile: 'Select File',
      depositFunds: 'DEPOSIT FUNDS',
      withdrawFunds: 'WITHDRAW FUNDS',
      ibPortal: 'IB PORTAL',
      download: 'DOWNLOADS',
      changeLeverage: 'CHANGE LEVERAGE',
      cancel: 'Cancel',
      changePw: 'CHANGE PASSWORD',
      forgetPw: 'FORGOT PASSWORD',
      confirm: 'Confirm',
      login: 'Login',
      signUp: 'Sign Up',
      forgotPw: 'Forgot Password?',
      completeQuestionnaire: 'COMPLETE QUESTIONNAIRE',
      identityProof: 'COMPLETE APPLICATION',
      upload: 'UPLOAD',
      export: 'EXPORT',
      ok: 'OK',
      hide: 'HIDE',
      showAll: 'Show all',
      continue: 'Continue',
      createLiveAccount: 'Create Live Account',
      redeem: 'Redeem',
      sendCode: 'Send Code',
      resendCode: 'Resend Code',
      requestCode: 'Send',
      verifyNow: 'Verify now',
      changeLeverage2: 'Change Leverage',
      copyTrade: 'Copy Trade',
      resetBalance: 'Reset Balance',
      resetPassword: 'Reset Password',
    },
    formValidation: {
      idNumberReq: 'Special characters (-,!,@, etc.) are not supported',
      idNumberReqNew: 'Special characters (-,!,@, etc.) are not supported, except"()"',
      accReq: 'Account Number is required',
      amtReq: 'Amount is required',
      ccReq: 'Credit card number is required',
      cardReq: 'Card Number is required',
      cardOnNameReq: 'Name on card is required',
      cardholderNameReq: "Cardholder's Name is required",
      digits16: 'Please enter 16 digits',
      digits3or4: 'Please enter 3 or 4 digits',
      digitsFirst6: 'Please enter first 6 digits',
      digitsLast4: 'Please enter last 4 digits',
      digitsLast3: 'Please enter last 3 digits',
      mid6Digits: 'Please enter middle {number} digits',
      expDateReq: 'Expiration date is required',
      cvvReq: 'Security Code CVV is required',
      file: 'Please upload a file',
      filePhoto: 'Credit Card Photo is required',
      fileReceipt: 'Please  upload a receipt',
      filePhotoReq: 'Card Photo is required',
      amtLarger: 'Amount cannot be null and must be greater than or equal to {minLimit} {currency}',
      amtCAD: 'Min ${minLimit} Max $9999.99 {currency}',
      amt0: 'Amount cannot be 0',
      amtLess: 'The amount of your deposit could not be more than {maxLimit} {currency}',
      note256: 'Note length limit is 256 characters',
      noteMoreThan: 'Note length limit is {limit} characters',
      noteLessThan: 'Note length limit is {limit} characters',
      emailFormat: 'Please input correct email address',
      payMethodReq: 'Payment method is required',
      expReq: 'Expiry date is required',
      bankNameReq: 'Bank Name is required',
      bankCityReq: 'Bank City is required',
      bankProvinceReq: 'Bank Province is required',
      bankAddressReq: 'Bank Address is required',
      bankBranchReq: 'Bank Branch is required',
      bankAccNumReq: 'Bank Account Number is required',
      ifscCodeReq: 'IFSC Code is required',
      bankBeneficiaryNameReq: 'Bank Beneficiary Name is required',
      beneficiaryNameReq: 'Beneficiary Name is required',
      bankHolderAddressReq: 'Account Holder’s Address is required',
      swiftReq: 'Swift code is required',
      accHolderAddress: 'Account holder address is required',
      bsbReq: 'BSB is required',
      accNumIBANReq: 'Bank Account Number/IBAN is required',
      accNameReq: 'Bank Account Name is requried',
      withdrawMethodReq: 'Please select a withdraw method',
      tnc: 'Please agree the Terms and Conditions.',
      copyTradingTnc: 'Please agree the Copy Trading Terms and Conditions.',
      cancellation: 'Please agree the PU Xtrader Agreement.',
      currPwReq: 'Current password is required',
      currPwWrong: 'The current password is incorrect',
      newPwReq: 'New password is required',
      newPwReqErr: 'Password strength does not fulfil requirement',
      newPwFormat:
        'Your password must be between 8-16 characters and a mix of letters (a-z and A-Z), numbers (0-9), and special characters like !@#$%^&*.()',
      newPwAccFormat:
        'Your password must be between 8-16 characters and a mix of letters (a-z and A-Z), numbers (0-9), and special characters like !@#$%^&*',
      IdenficalPw: 'The new password cannot be the same as the old one',
      confirmPwReq: 'Please input the new password again',
      confirmNotMatch: 'Two inputs do not match',
      pwReq: 'Password is required',
      accCurrReq: 'You must select an account currency',
      tradAccReq: 'Please select a trading account',
      answerReq: 'Please select',
      bankCardReq: 'Please select a card',
      phoneNumberReq: 'Mobile Phone Number for Bank is required',
      nationaIdReq: 'National ID is required',
      numberReq: 'Please enter number',
      alphanumericReq: 'Please enter alphanumeric',
      idTypeReq: 'Please choose an ID Type',
      dobReq: 'Please choose your date of birth',
      pobReq: 'Please choose your place of birth',
      nationalityReq: 'Please choose your nationality',
      titleReq: 'Please choose your title',
      firstNameReq: 'Please enter your first name',
      lastNameReq: 'Please enter your last name',
      emailReq: 'Please enter your email address',
      addressReq: 'Please enter your address',
      suburbReq: 'Please enter your city or suburb',
      stateReq: 'State or province is required',
      postcodeReq: 'Please enter your postcode',
      countryReq: 'Please select your country of residency',
      masterCardReq: 'Please use a Master Card to deposit',
      masterCardJCBReq: 'Please use a Master Card/JCB to deposit',
      masterCardVisaReq: 'Please use a Master Card/Visa to deposit',
      masterCardAmexJCBReq: 'Please use a Master Card/AMEX/JCB to deposit',
      bankCodeReq: 'Please input the Card Issuing Bank',
      dynamicReq: '{dynamic} is required',
      mobReq: 'mobile is required',
      resetBalAmt: 'Please set the amount between 0 and 1,000,000',
      verCodeReq: 'Verification code is required',
      common: 'This field is required.',
      na: 'NA',
      sameAsPOA: 'Entry needs to be same as POA',
      sameAsID: 'Entry needs to be same as ID',
      notBlank: 'To be left blank if not applicable',
      digits12WithoutDash: 'Please enter 12 digits, without "-"',
      enterAsPerId: 'Enter as per ID',
      AlphanumericOnlyNotSpace: 'Alphanumeric only. Space is not allowed.',
      AlphanumericOnly: 'Alphanumeric only.',
      NumbersSymbol: 'Numbers and symbol only.',
      NumbersOnlyNotSpace: 'Numbers only. Space is not allowed.',
      NumbersOnly: 'Numbers only.',
      LettersSymbol: 'Letters and symbol only.',
      LettersSymbolNotSpace: 'Letters and symbol only. Space is not allowed.',
      LettersOnlyNotSpace: 'Letters only. Space is not allowed.',
      NotSpace: 'Space is not allowed.',
      selectBAReq: 'Please select a bank account to proceed.',
      validateCcDetails:
        'Please fill in all the credit card details before submitting your withdrawal',
      clickUpdate: 'Please click the update button below',
    },
    withdrawChannel: {
      limitTitle: 'Withdrawals are temporarily not supported',
      limitPassword:
        'Due to the modification of your login password, withdrawals are temporarily not supported for {day} days to ensure your account security. This restriction is expected to be lifted in {hour} hours.',
      limitUserLogin:
        'Due to the modification of your login email/phone number, withdrawals are temporarily not supported for {day} days. This restriction is expected to be lifted in {hour} hours.',
      limitSecurityAuthenticator:
        'Due to the modification of your Security Authenticator APP, withdrawals are temporarily not supported for {day} days to ensure your account security. This restriction is expected to be lifted in {hour} hours.',
      withdrawalMessage:
        'For this Account <span class="count">{account}</span>,<br> credit amounting to <span class="count bold"> {currencyType} {deductCredit} </span> will be deducted upon withdrawal. The actual deduction amount of the bonus is subject to the audit calculation result.',
      banktransferbpaypolipay: 'Bank Transfer/BPay/PoliPay',
      banktransfer: 'International Bank Transfer',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'UnionPay Transfer',
      fasapay: 'FasaPay',
      creditcard: 'Credit Card',
      bpay: 'Bpay',
      creditdebitcard: 'Credit/Debit Card',
      bankwiretransferinternational: 'Bank Wire Transfer (International)',
      banktransferaustralia: 'Bank Transfer (Australia)',
      banktransferinternational: 'International Bank Transfer',
      banktransferchina: 'Bank Transfer (International)',
      banktransferequals: 'Bank Transfer (International)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'Broker to Broker Transfer',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Internet Banking (Thailand)',
      internetbankingnigeria: 'Internet Banking (Nigeria)',
      internetbankingvietnam: 'Internet Banking (Vietnam)',
      internetbankingmalaysia: 'Internet Banking (Malaysia)',
      internetbankingindonesia: 'Internet Banking (Indonesia)',
      internetbankingkorea: 'Internet Banking (Korea)',
      internetbankingindia: 'Internet Banking (India)',
      internetbankingphilippine: 'Internet Banking (Philippine)',
      internetbankingsouthafrica: 'Internet Banking (South Africa)',
      internetbankingsouthkorea: 'Internet Banking (South Korea)',
      internetbankinguganda: 'Internet Banking (Uganda)',
      internetbankingrwanda: 'Internet Banking (Rwanda)',
      internetbankingzambia: 'Internet Banking (Zambia)',
      internetbankingcongo: 'Internet Banking (Congo)',
      internetbankingcameroon: 'Internet Banking (Cameroon)',
      internetbankingburundi: 'Internet Banking (Burundi)',
      internetbankingkenya: 'Internet Banking (Kenya)',
      internetbankingghana: 'Internet Banking (Ghana)',
      internetbankingtanzania: 'Internet Banking (Tanzania)',
      internetbankingcanada: 'Interac® eTransfer',
      internetbankingcanadatm: '®Trade-mark of Interac Corp. Used under license',
      internetbankingbrazil: 'Internet Banking (Brazil)',
      internetbankingjapan: 'Internet Banking (Japan)',
      internetbankinguae: 'Internet Banking (UAE)',
      internetbankingtaiwan: 'Internet Banking (Taiwan)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Vietnam Bank Transfer',
      nigeriabanktransfer: 'Nigeria Bank Transfer',
      polipayment: 'Poli Payment',
      bankwiretransferaustralia: 'Bank Wire Transfer (Australia)',
      thailandinstantbankwiretransfer: 'Thailand Instant Bank Wire Transfer',
      nigeriainstantbankwiretransfer: 'Nigeria Bank Transfer',
      vietnaminstantbankwiretransfer: 'Vietnamese Instant Bank Wire Transfer',
      malaysiainstantbankwiretransfer: 'Malaysian Instant Bank Transfer',
      indonesiainstantbankwiretransfer: 'Indonesia Instant Bank Transfer',
      indiainstantbanktransfer: 'India Instant Bank Wire Transfer',
      southkoreabanktransfer: 'South Korea Local Bank Transfer',
      southafricainstantbankwiretransfer: 'South Africa Instant Bank Wire Transfer',
      philippinesinstantbankwiretransfer: 'Philippine Instant Bank Transfer',
      ugandabanktransfer: 'Uganda Local Bank Transfer',
      rwandabanktransfer: 'Rwanda Local Bank Transfer',
      zambiabanktransfer: 'Zambia Local Bank Transfer',
      congobanktransfer: 'Congo Local Bank Transfer',
      cameroonbanktransfer: 'Cameroon Local Bank Transfer',
      burundibanktransfer: 'Burundi Local Bank Transfer',
      kenyabanktransfer: 'Kenya Local Bank Transfer',
      ghanabanktransfer: 'Ghana Local Bank Transfer',
      tanzaniabanktransfer: 'Tanzania Local Bank Transfer',
      indiabanktransfer: 'India Bank Transfer',
      banktransferbpaypolipayaustralia: 'Bank Transfer/BPay/PoliPay (Australia)',
      banktransferbpaypolipayinternational: 'Bank Transfer/BPay/PoliPay (International)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Canadian Local Bank Transfer',
      cryptobitcoin: 'Crypto-Bitcoin',
      cryptotetheromni: 'Crypto-Tether(OMNI)',
      cryptotethererc20: 'Crypto-Tether(ERC20)',
      cryptotethertrc20: 'Crypto-Tether(TRC20)',
      cryptocurrencybitcoin: 'Cryptocurrency-Bitcoin',
      cryptocurrencyusdt: 'Cryptocurrency-USDT',
      cryptobitcoinapp: 'BTC-APP',
      cryptotetheromniapp: 'USDT-OMNI-APP',
      cryptobitcoincpsapp: 'BTC-CPS-APP',
      cryptotetheromnicpsapp: 'USDT(OMNI)-CPS-APP',
      cryptobitcoinerc20cpsapp: 'USDT(ERC20)-CPS-APP',
      cryptobitcointrc20cpsapp: 'USDT(TRC20)-CPS-APP',
      cryptocurrencyethcps: 'Cryptocurrency-ETH',
      cryptocurrencyusdcerc20: 'Cryptocurrency-USDC(ERC-20)',
      cryptocurrencyusdccps: 'Cryptocurrency-USDC(ERC-20)',
      cryptocurrencyeth: 'Cryptocurrency-ETH',
      cryptocurrencyusdc: 'Cryptocurrency-USDC(ERC20)',
      gopay: 'GoPay',
      momopay: 'Momopay',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      upi: 'UPI',
      ebuy: 'EBuy',
      alipay: 'Alipay',
      perfectmoney: 'Perfect Money',
      localdepositor: 'Local Depositor',
      E_WALLET: 'E-WALLET',
      CRYPTOCURRENCY: 'CRYPTOCURRENCY',
      ideal: 'Netherlands Instant Bank Transfer',
      LOCAL_TRANSFER: 'LOCAL BANK TRANSFER',
      archiveThisCard: 'Please archive this card if it is no longer valid or in use',
      manageCreditCards: 'Manage credit cards',
      update: 'Update',
      archive: 'Archive',
      updateExpiryMsg: 'Please update the expiry date of your card to use your card for withdrawal',
      requiredDocument: 'Documents required for archiving the card',
      officialLetter: 'Official bank letter/email stating the card is no longer available',
      bankStatement: 'Bank Statement with transaction made with this card',
      expiredCard: 'Expired/Unavailable Cards',
      confirmArchive: 'Are you sure you want to archive this card?',
      confirmUpdate:
        'Are you sure you want to update the expiry date of this card? Note: Puprime will not be responsible if the withdrawal failed due to the incorrect expiration date provided',
      pendingArchiveRequest:
        'Your credit card archive request is currently pending approval, please wait until the process is completed before making a withdrawal, or contact our customer service via live chat to expedite the process.',
      brazilbanktransfer: 'Brazil Bank Transfer',
      pix: 'PIX',
      applepay: 'Apple Pay',
      japanbanktransfer: 'Japan Bank Transfer',
      interac: 'Interac',
      spei: 'SPEI',
      uaelocalbanktransfer: 'UAE local Bank Transfer',
      uaebanktransfer: 'UAE Bank Transfer',
      ewallet: 'E-wallet',
      airtm: 'AirTM',
      netherlandsinstantbanktransfer: 'Dutch Instant Bank Transfer',
      eurosepa: 'EURO SEPA',
      internetbankingeu: 'Internet Banking (EU)',
    },
    socialMedia: { title: 'Follow Us' },
  },
  home: {
    explanation:
      'Credits can be utilised to increase trading equity. Credits are not available for withdrawal as it is kept separate from your account balance.',
    pendingTooltip:
      'Once your live account is approved you will receive an email containing your MT{number} login details so you can start trading!  Meanwhile, you can fund your account to receive priority account opening.',
    leverage: {
      tnc: '<p>By submitting this request I acknowledge that I am aware that high leverage carries a large amount of risk to my capital and there is a possibility I could sustain a loss greater than and not limited to the capital I have deposited.<br><br>I agree that the products issuer is entitled to review my trading activities and adjust the leverage levels on my trading account at its discretion and without any prior warnings or notifications.<br><br>I understand that by choosing high leverage, I can trade with bigger margin but potentially incur significant losses.</p>',
      changeLeverageConfirm:
        'Your request has been submitted and will be processed by our support team shortly.',
      changeLeverageAutoComplete: 'Your leverage change request is completed',
      changeLeverageReject: 'Leverage change request has been rejected',
      changeLeverageFail: 'Your account is not eligible to use this leverage.',
      changeLeverageDefault:
        'An error has occurred, please contact {supportEmail} to finalise your application',
      alreadyApplied:
        'You have already requested to change the leverage on your account and it is currently under review by the {platform} support team. Please wait for this request to be finalised before submitting another request.',
      reduceLeverageWarn:
        'Please note: Reducing your leverage may result in the forced closure of open positions if your account has insufficient margin.',
      reduceLeverageConfirm: 'Do you wish to continue?',
      autoRestore:
        'As your account equity exceeded USD 20,000, the maximum leverage that you are eligible to use is 500:1. You may change leverage to 1000:1 when your account equity is smaller than or equals to USD 20,000',
    },
    pw: {
      error: 'Failed to reset your password. Please try again later',
      confirm:
        'Your password has been updated.<br>You may now use your new password to access your MT4/MT5 trading account.',
      email:
        '<p class="first_info">We have just sent you an email with instructions to reset your password.</p><p>Please ensure you check your email’s spam folder.</p>',
    },
    activate: {
      question: 'Do you wish to re-activate your live account?',
      confirm:
        'We have received your activation request.<br>Your account will be activated shortly.',
    },
    accStatus: {
      active: 'Active',
      rejected: 'Rejected',
      inactive: 'Inactive',
      processing: 'Processing',
      activate: 'Activate',
      expired: 'Expired',
    },
    pro1000: {
      desc1: 'The following restrictions apply to account with leverage of 1000:1:',
      conditions: [
        'Leverage limits may vary according to applicable regulations.',
        'Leverage may be automatically lowered to 1:500 by the system if the selected account equity exceeds USD 20,000 or equivalent. If there are open trades, an email notification will be sent prior to leverage adjustment.',
        "Please note that 1:1000 leverage is only available for financial instruments such as major Forex pairs and Gold, while leverage for other instruments is held in accordance with the instruments' fixed margin requirements and is not affected by the 1:1000 leverage.",
      ],
      desc2:
        'By submitting this request, I acknowledge that I am aware that high leverage carries a large amount of risk to my capital and there is a possibility I could sustain a loss greater than and not limited to the capital I have deposited.',
      desc3:
        'I agree that the products issuer is entitled to review my trading activities and adjust the leverage levels on my trading account at its discretion and without any prior warnings or notifications.',
      desc4:
        'I understand that by choosing high leverage, I can trade with bigger margin but potentially incur significant losses.',
    },
    pro2000: {
      desc1: 'The following restrictions apply to account with leverage of 2000:1:',
      conditions: [
        'The selected account is not eligible to participate in credit-related promotion.',
        'Leverage may be automatically lowered to 1:500 by the system if the selected account equity exceeds USD 5,000 or equivalent. If there are open trades, an email notification will be sent prior to leverage adjustment.',
        'Leverage limits may vary according to applicable regulations.',
        'Please note that 1:2000 Leverage is not available for financial instruments such as Cryptocurrencies, Shares, Silver, and Commodities. The margin for these instruments is held in accordance with the instruments’ fixed margin requirements and is not affected by 1:2000 Leverage.',
      ],
      desc2:
        'By submitting this request, I acknowledge that I am aware that high leverage carries a large amount of risk to my capital and there is a possibility I could sustain a loss greater than and not limited to the capital I have deposited.',
      desc3:
        'I agree that the products issuer is entitled to review my trading activities and adjust the leverage levels on my trading account at its discretion and without any prior warnings or notifications.',
      desc4:
        'I understand that by choosing high leverage, I can trade with bigger margin but potentially incur significant losses.',
    },
    resetBalance: {
      descWait1: 'Your {credit} account credit will be deducted after negative balance reset.',
      descWait2: 'Do you agree to proceed?',
      resetBtn: 'Reset',
      title1: 'Wait A Moment',
      title2: 'Request Unsuccessful',
      resetSuccess: 'Reset successfully',
    },
    copyTrading: {
      actionTooltip:
        'This action is not applicable for Copy Trading account. Please log in to PU Prime APP to access Copy Trading account.',
    },
  },
  paymentDetails: {
    subHeader: 'ADD A PAYMENT METHOD',
    payMethod: 'Payment Method',
    tip: '<p>Select your preferred payment method for future deposits and withdraws from the menu below.</p><br />\n    <p><b>Please note:</b> To ensure your deposit and withdrawal requests can be processed promptly, AML/CTF regulations require that the name shown on your chosen payment method is\n     <b>{individualUserName}</b>.</p>',
    result: {
      defaultSuccessMessage:
        'Thank you for submitting the funding method for your trading account. We are currently processing your request and will be in touch shortly.',
      unionPayCardSuccessMessage:
        'Union Pay Card details successfully submitted, your new bank details will be available once it gets approved',
    },
    history: { header: 'PAYMENT METHODS' },
    cc: {
      field: {
        ccNum: 'Credit Card Number',
        first6: 'First Six',
        last4: 'Last Four',
        withrawlable:
          'The maximum available withdrawal for this card is {amount}. To withdraw funds exceeding the available limit, please choose another card or withdrawal method.',
      },
      formValidation: { upload: 'Credit Card Photo is required' },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: 'Fetching your deposit status... {countDown} seconds left.',
      successMsg: 'Thank you for your deposit, your funds will appear in your account shortly.',
      rejectMsg: 'Rejected',
      failureMsg: 'Your deposit was unsuccessful. Please try again.',
      message:
        'Thank you for funding your account. Your deposit will be manually approved by the {platform} accounts team shortly. The funds will be deposited into your account once it is approved',
      defaultException:
        'Your transaction is currently under processing, to confirm your deposit with {platform}, please contact {email}',
    },
    failed: {
      countDownMsg: 'Fetching your deposit status... {countDown} seconds left.',
      rejectMsg: 'Rejected',
    },
  },
  deposit: {
    alert: { depositBonus50: 'You’re eligible to get Deposit Bonus. Activate before deposit!' },
    default: {
      deposit: 'Deposit',
      reference: 'Your Reference',
      sendReceipt: 'Send Receipt',
      sendReceiptTip:
        'Once you have made the transfer, please complete the form below and attach a screenshot, photo, or scanned copy of the bank transfer receipt that clearly shows your full name, account number, and deposit amount so we can process your transaction.',
      reminder: 'Please allow 1-2 business days for the funds to be credited to your account.',
      successMsg:
        'Funds will appear in your trading account once we receive the deposit and verify your transaction receipt.',
      alt: 'Alternatively, you can email your transaction receipt to {mailTo} at your earliest convenience.',
      reminderFundAppear:
        'Your funds will appear in your account as soon as we receive the funds from your previous broker.',
      failed: 'Deposit failed, please try again later.',
      fee: 'FEE',
      instant: 'INSTANT',
      hour: 'HOUR',
      hours: 'HOURS',
      businessDays: 'BUSINESS DAYS',
      withinOneBusinessDays: 'WITHIN 1 BUSINESS DAY',
      secondaryNGN:
        '(You may use this Account Number for this transaction if you prefer to use Nigerian Naira (NGN) currency instead.)',
      disclaimer:
        "<li>All your payments to {platform} are confidential and are only used for deposit and withdrawal in your trading account of {platform}. All E-payments are processed by SSL (Secure Socket Layer) technology and encrypted to ensure the security.</li>\n      <li>{platform} strictly doesn't accept payments from any third-party. All funds deposited into your trading account must be under the same name as your {platform} client portal account.</li>\n      <li>Client understands that submitting a deposit request does not constitute an acceptance of funds by {platform}. If your account is close to or in a margin call, please make sure you have other arrangements in place.</li>\n      <li>{platform} will not be responsible for any delays or errors caused by payments systems.</li>\n      <li>{platform} will not be responsible any fees that the sending financial institution or third-party payment providers may charge you for sending payments.</li>\n      <li>Clients acknowledge that they may be required to provide information on the source(s) of the deposit(s), the Clients’ wealth in general and any other information {platform} deems necessary to identify the source of funds when processing a deposit request and agree to provide all information required by {platform}. Failure to provide the required information may result in significant delays in the process of your requests. {platform} will not be responsible for any delays or losses suffered as results of that delay.</li>",
      channels: {
        creditOrDebit: 'Credit/Debit Card',
        i12BankTransfer: 'International Bank Transfer',
        sticPay: 'SticPay',
        unionPay: 'Union Pay Deposit',
        unionPayCN: 'China Union Pay Transfer',
        fasa: 'Fasapay',
        skrill: 'Skrill/Moneybookers',
        vietInstant: 'Vietnam Instant Bank Wire Transfer',
        thaiInstant: 'Thailand Instant Bank Wire Transfer',
        nigeriaInstant: 'Nigeria Instant Bank Wire Transfer',
        nigeriaLocalPayment: 'Nigeria Local Payment Methods',
        malayInstant: 'Malaysia Instant Bank Transfer',
        indonesiaInstant: 'Indonesia Instant Bank Transfer',
        philippineInstant: 'Philippine Instant Bank Transfer',
        mobilePay: 'Mobile Pay',
        interac: 'Interac®',
        bitwallet: 'bitwallet',
        indiaInstant: 'India Instant Bank Wire Transfer',
        astropay: 'Astropay',
        perfectmoney: 'Perfect Money',
        vietnamqr: 'Vietnam QR',
        nigeriabanktransfer: 'Nigeria Bank Transfer',
        indiabanktransfer: 'India Bank Transfer',
        southkoreabanktransfer: 'South Korea Bank Transfer',
        cpsusdttrc20: 'CPS-USDT-TRC20',
        cpseth: 'CPS-ETH',
        cpsusdcerc20: 'CPS-USDC(ERC20)',
        cpsbitcoin: 'CPS-BITCOIN',
        cpsusdterc20: 'CPS-USDT-ERC20',
        ideal: 'Netherlands Instant Bank Transfer',
        alipay: 'Alipay',
        ebuy: 'EBuy',
        indonesiainstantbanktransfer: 'Indonesia Instant Bank Transfer',
        neteller: 'Neteller',
        malaysiainstantbanktransfer: 'Malaysia Instant Bank Transfer',
        indiainstantbanktransfer: 'India Instant Bank Transfer',
        thailandinstantbanktransfer: 'Thailand Instant Bank Transfer',
        indiaupi: 'India UPI',
        indonesiavirtualaccountbanktransfer: 'Indonesia Virtual Account Bank Transfer',
        philippinesinstantbanktransfer: 'Philippines Instant Bank Transfer',
        cryptocurrencydeposit: 'Cryptocurrency deposit',
        chinaunionpaytransfer: 'China UnionPay transfer',
        nigeriainstantbanktransfer: 'Nigeria Instant Bank Transfer',
        kenyainstantbanktransfer: 'M-PESA',
        southafricainstantbanktransfer: 'South Africa Instant Bank Transfer',
        vietnammomopay: 'Vietnam MomoPay',
        indonesiap2pbanktransfer: 'Indonesia P2P Bank Transfer',
        vietnamp2pbanktransfer: 'Vietnam P2P Bank Transfer',
        vietnaminstantbanktransfer: 'Vietnam Instant Bank Transfer',
        camerooninstantbanktransfer: 'Cameroon Instant Bank Transfer',
        ghanainstantbanktransfer: 'Ghana Instant Bank Transfer',
        rwandainstantbanktransfer: 'Rwanda Instant Bank Transfer',
        tanzaniainstantbanktransfer: 'Tanzania Instant Bank Transfer',
        ugandainstantbanktransfer: 'Uganda Instant Bank Transfer',
        cryptocurrencybitcoin: 'Bitcoin',
        cryptocurrencyusdt: 'USDT-TRC20',
        cryptocurrencyusdccps: 'USDC-ERC20',
        cryptocurrencyusdctrccps: 'USDC-TRC20',
        cryptocurrencyethcps: 'ETH',
        netherlandsinstantbanktransfer: 'Dutch Instant Bank Transfer',
        eurosepa: 'EURO SEPA',
        taiwanconveniencestore: 'Taiwan Convenience Store',
        euroinstantbanktransfer: 'Euro Instant Bank Transfer',
        taiwanbanktransfer: 'Taiwan Bank Transfer',
      },
      rate: {
        rate: 'Exchanges Rates({oldCurrency}/{newCurrency}): ',
        result: {
          rateError: 'Fetch {oldCurrency} to {newCurrency} rate failed.',
          channelError: 'Fetch payment channel failed.',
        },
      },
      cn: { rate: 'Exchanges Rates(USD/RMB): ', amt: 'RMB: ' },
      upgrade:
        'The payment channel is currently under maintenance and only available to users who have successfully deposited funds through this channel before.',
      depositInstruction: 'Deposit Instruction',
      depositNote: 'Deposit Note',
    },
    method: {
      desc: 'How to fund your account with {method}',
      inst1: '{number}.   Complete the form below and press ‘Submit’',
      inst2:
        '{number}.   Select bank in bank selection page and login to your {country} online bank',
      inst3: '{number}.   Follow the instruction and make payment via your Online/Mobile Bank',
      inst4: '{number}.   You will then be redirected to another form to enter your card details',
      inst5: '{number}.   After entering your card details, press ‘Submit’',
      inst6:
        '{number}.   You will be redirected back to the client portal and your transaction will be finalized',
      inst7:
        '{number}.   If your currency is not {currency}, the bank may charge you a conversion fee according to the current bank exchange rate',
      inst8:
        '{number}.   Credit Card deposits using EUR, GBP, AUD, SGD, NZD, HKD, JPY and USD are limited to a maximum of $5,000USD per transaction. Credit Card deposits denominated in CAD are accepted up to $6,500CAD per transaction',
      inst9: '{number}.   Maximum $1500 USD per deposit',
      inst10: '{number}.   A Payment details page will pop up',
      inst11: '{number}.   Select bank in bank selection page and login to your online bank',
      inst12: '{number}.   We can accept payment in CAD. Maximum $9,999 CAD per deposit',
      inst13: '{number}.   Log in to your Paystack account',
      inst14: '{number}.   Follow the prompts in Paystack account to complete your payment',
      form: { header: '{method} Form' },
    },
    time: {
      instant: '24/7 Instant',
      onehour: '1 HOUR INSTANT  ',
      twotofivebusinessdays: '2-5 Business days',
    },
    reminder: {
      inst1: 'Complete the form below and press ‘Submit’',
      inst2: 'Log in to your {method} account',
      inst3: 'Follow the prompts in {method} account to complete your payment',
      inst4: 'You will then be redirected to another form to enter your card details',
      inst5: 'After entering your card details, press ‘Submit’',
      inst6:
        'You will be redirected back to the client portal and your transaction will be finalized',
      inst7: 'You will then be directed to a new window displaying a {method} wallet address',
      inst8:
        'Copy the {method} wallet address and then log in to your personal {method} wallet and transfer your desired amount to the {method} address.',
      inst9: 'Scan the QR code to open your mobile banking application',
      inst10: 'Follow the prompts on your mobile device to complete your deposit',
      inst11:
        'Please make sure to pay online via the redirected payment page to avoid any loss of fund.',
      inst12: 'Select bank in bank selection page and login to your online bank',
      inst13: 'Follow the instruction and make payment via your Online/Mobile Bank',
      inst14:
        'Once you have made the transfer, please complete the form below and attach a copy of the bank transfer receipt that clearly shows your full name, account number, and deposit amount so we can process your transaction.',
      inst15: 'Please allow 2-5 business days for the funds to be credited to your account.',
      inst16: 'Please allow 1 business day for the funds to be credited to your account.',
      inst17:
        'Please ensure that clearly shows your full name, account number, and deposit amount so we can process your transaction.',
      inst18: 'Please allow {time} for the funds to be credited to your account.',
      inst19: 'Enter your UPI ID/VPA or scan the QR code to open your mobile banking application.',
      inst20: 'You will then be directed to a new window displaying the instructions by M-PESA 2.',
      inst21: 'Follow the instruction and check with your Mpesa APP.',
      inst22: 'Fill up the form below and press ‘Submit’',
      inst23: 'You will then be redirected to another form in {name} to enter your card details',
      inst24: 'After entering your card details in {name}, press ‘Submit’',
      note1:
        '{method} deposits are limited to $25,000 USD per transaction and a maximum of $75,000 USD per day',
      inst25: 'Complete the form below and select ‘Submit’',
      inst26: 'Select bank in bank selection page and login to your online bank',
      inst27: 'Follow the instruction and make payment via your Online/Mobile Bank',
      inst28:
        'You will be redirected back to the client portal and your transaction will be finalized',
      inst29: 'Follow the prompts in account to complete your payment',
      inst30: 'Complete the form below and press "Continue"',
      currencyConversion:
        'If the currency of your remittance does not match the currency of your trading account, we will convert the funds into the account currency for the deposit',
    },
    notes: {
      inst1:
        'Fasapay deposits are limited to $25,000 USD per transaction and a maximum of $75,000 USD per day',
      inst2: 'We can accept payment in AUD, USD, EUR, CAD, USC and GBP',
      inst3: 'Accounts can only be funded in the same currency as your MT4/MT5 trading account.',
      inst4: 'We accept payment in USD, EUR, GBP, USC and CAD',
    },
    southAfrica: { header: 'South Africa Instant Bank Wire Transfer', country: 'South Africa' },
    idn: { header: 'Indonesia Instant Bank Transfer', country: 'Indonesia' },
    ida: { header: 'India Instant Bank Wire Transfer', country: 'India' },
    nigeria: { header: 'Nigeria Instant Bank Wire Transfer', country: 'Nigeria' },
    nigeriaPaystack: { header: 'Nigeria Local Payment Methods', country: 'Nigeria' },
    thailand: { header: 'Thailand Instant Bank Wire Transfer', country: 'Thailand' },
    malay: { header: 'Malaysia Instant Bank Transfer', country: 'Malaysia' },
    php: { header: 'Philippine Instant Bank Transfer', country: 'Philippine' },
    vietnam: { header: 'Vietnam Instant Bank Wire Transfer', country: 'Vietnam' },
    korea: { header: 'South Korea Local Bank Transfer' },
    uganda: { header: 'Uganda Local Bank Transfer', country: 'Uganda' },
    rwanda: { header: 'Rwanda Local Bank Transfer', country: 'Rwanda' },
    zambia: { header: 'Zambia Local Bank Transfer', country: 'Zambia' },
    congo: { header: 'Congo Local Bank Transfer', country: 'Congo' },
    cameroon: { header: 'Cameroon Local Bank Transfer', country: 'Cameroon' },
    burundi: { header: 'Burundi Local Bank Transfer', country: 'Burundi' },
    kenya: { header: 'Kenya Local Bank Transfer', country: 'Kenya' },
    ghana: { header: 'Ghana Local Bank Transfer', country: 'Ghana' },
    tanzania: { header: 'Tanzania Local Bank Transfer', country: 'Tanzania' },
    canada: {
      header: 'Interac®',
      note1: 'We accept payment in CAD only. Maximum ${maxLimit} CAD per transaction',
    },
    creditOrDebit: {
      header: 'Credit Card',
      worldcard: {
        note1:
          'Credit Card deposits using EUR, GPB and USD are limited to the maximum amount of $20,000USD per transaction.Credit Card deposits using EUR, GPB and USD are limited to the maximum amount of $20,000USD or equivalent per transaction',
        note2:
          'Number of different cards per user is 3. Any deposit attempt beyond the limitation will not be accepted',
      },
      virtualPay: {
        note1:
          'Credit Card deposits using EUR, GBP, AUD, SGD, NZD, HKD, JPY, USC and USD are limited to a maximum of 10,000 USD per transaction.',
        note2:
          '50,000 USD or 10 transactions max per card within 24 hours. Any deposit attempt beyond the limitation will not be accepted',
      },
      Sdpay: {
        note1:
          'Credit Card deposits using EUR, GBP and USD are limited to a maximum of $2,000 USD or equivalent currencies per transaction.',
        note2:
          'Maximum 8 transactions per day. Any deposit attempt beyond the limitation will not be accepted',
        note3:
          'Please note that there may be a hold apply on your first-time payments. Please be patient whiles our team is verifying your payment, or you may contact our customer support team for further assistance.',
      },
      maxisPay: {
        note1:
          'Credit Card deposits using USD are limited to the maximum amount of $5,000USD per transaction.',
      },
      bridgerPay: {
        note1: 'The name on your credit card must match with your account name',
        note2:
          "Please note that the transaction description that appears on your card statement will vary depending on the financial gateway used. If you have any concerns about the transaction's description, please contact <a href='mailto:{mail}'>{mail}</a>",
        note3:
          "The charge will appear on your credit card or bank statement as <a href='{href}' target=\"_blank\">puprime.com</a>. For information with your transaction, please contact our customer service via: <a href='mailto:{mail}'>{mail}</a> and +248 4373 105. Finzero Cap Ltd, registered in the Republic of Cyprus with registration number HE414308 and registered address at 62 Athalassas, Mezzanine, Strovolos 2012, Nicosia, Cyprus",
      },
    },
    net: {
      header: 'Neteller',
      note1: 'We can accept payment in AUD, USD, EUR, CAD and GBP',
      note2: 'Accounts can only be funded in the same currency as your MT4 trading account.',
      note3:
        'Neteller will automatically convert funds to your account’s base currency if the incorrect email is used. Fees may apply.',
      note4: 'Deposits must be in the same name as your PU Prime account.',
    },
    vload: {
      header: 'VLOAD DEPOSIT',
      inst1: '2.   We can accept payment in USD, EUR, and JPY',
      inst2:
        '<li>3.   Purchase Vload Voucher, click <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">here</a>.</li>',
      form: { voucherpin: 'Voucher Pin', voucherpinReq: 'Voucher Pin is required' },
    },
    bankWireAu: {
      header: 'BANK WIRE TRANSFER: AUSTRALIA',
      instruction:
        'Funds can be transferred to {platform} via your financial institution’s online banking facility, or alternatively by contacting your financial institution and requesting the transfer to {platform}.',
      tip: 'Select the account currency you would like to deposit in. Please ensure you select the same currency as the currency of your trading account',
      form: {
        bankDetails: 'Bank Details(AUD Deposit Only)',
        refTip: 'Please specify your Login Number here',
        afp: 'Australian Fast Payment(Domestic AUD Deposit ONLY)',
        payIdOrABNNum: 'PayID/ABN Number',
        payIdName: '{platformEntityName} Pty Ltd',
        bankTransferRefTip:
          'Please include your {platform} trading account number in the bank transfer reference field.',
      },
    },
    bPay: {
      header: 'BPAY DEPOSIT',
      option: 'How to fund your account with BPay (Australia only)',
      inst1:
        '1.   Select your MT4/MT5 account to view your BPay Biller Code and BPay Reference Number',
      inst2:
        '2.   Enter the payment details, your BPay Biller Code and BPay Reference Number into your internet banking facility and submit your payment',
      inst3:
        '3.   Return to this page and complete the BPay form below to ensure we can promptly process your transaction',
      form: {
        header: 'BPAY FORM',
        billerCode: 'BPay Biller Code',
        refNum: 'BPay Reference Number',
        receipt: 'BPAY transfer receipt',
        reminderNotUploaded:
          'We have noticed that you have not uploaded your transaction receipt. In order to process your deposit, please email a copy of the receipt to {mailTo} at your earliest convenience.',
        reminderProc:
          'Please note. Your transaction CANNOT be processed until we receive your receipt.',
      },
    },
    b2b: {
      header: 'BROKER TO BROKER',
      option: 'How to transfer funds from another broker to {platform}',
      inst1: '1.   Download and complete the {download}',
      inst2:
        '2.   Complete the online form below and upload your completed Broker to Broker Transfer Form',
      inst3:
        '3.   Send a copy of your completed Broker to Broker form to your previous broker to initiate the transfer',
      b2bForm: 'Broker to Broker Transfer Form',
      formValidation: { upload: 'Broker to Broker Form is required' },
    },
    cc: {
      header: 'CREDIT OR DEBIT CARD DEPOSIT',
      remind:
        'Should you experience any difficulty funding your account using the Primary method, please use the SecondaryCredit/Debit method',
      field: {
        fundNow: 'Fund Now by Credit / Debit Card',
        newCard: 'New Card',
        cardNum: 'Credit Card Number',
        nameOnCard: 'Name on card',
        expDate: 'Expiration Date',
        cvv: 'Security Code CVV',
        bankCode: 'Card Issuing Bank',
        example: 'Simple Card',
      },
      placeholder: { bankCode: 'Please enter your card issuing bank name' },
      cvvTip: 'The 3 digits displayed on the back of your card',
      threeDomainSecQ: 'Does your card supports 3D-Secure?',
      threeDomainSec:
        'What is 3D Secure authentication? <br /><strong>3D Secure authentication is a fraud prevention method recognised by Visa and Mastercard.<br /><br />If your card supports 3D Secure, your funds will be processed immediately.</strong>',
      cardUpload:
        'The card you are using to deposit funds hasn’t been linked to your trading account. Please upload a photo of the front of your card for verification. (Please cover the middle 6 digits)',
      result: {
        success:
          'Thank you. We are currently verifying your card details, and once verified funds should appear in your account within 24 hours.',
        depositSuccess:
          'Thank you for your deposit, your funds will appear in your account shortly',
        error:
          'In order to protect the security of your account,<br> you have reached the maximum attempted deposit transactions and have temporarily suspended your deposits.<br> Please contact {email}',
      },
      tipOnlyTaiwan:
        'Dear customer, please be aware that withdrawals via credit card may delay due to bank processing, and the arrival time depends entirely on each bank. If you have urgent fund needs, it is recommended to use other methods to deposit/withdraw.',
      reminder:
        '<li>1. Credit Card deposits using EUR, GBP and USD are limited to a maximum of $1,900 USD or equivalent currencies per transaction.</li>\n      <li>2. Maximum 8 transactions per day.</li>\n      <li>3. Please note that there may be a hold apply on your first-time payments. Please be patient whiles our team is verifying your payment, or you may contact our customer support team for further assistance.</li>',
    },
    fasa: {
      header: 'FASAPAY',
      description: 'How to fund your account with FasaPay',
      form: { header: 'FASAPAY FORM' },
    },
    intSwift: {
      header: 'BANK WIRE TRANSFER: INTERNATIONAL SWIFT',
      desc: 'Funds can be transferred to {platform} via your financial institution’s online banking facility, or alternatively by contacting your financial institution and requesting the transfer to {platform}.',
      desc2:
        'Once you have made the transfer, please complete the form below and attach a copy of the bank transfer receipt that clearly shows your full name, account number, and deposit amount so we can process your transaction.',
      desc3:
        "Transfer must be made from your own bank account. Payments from another person's Bank account will be declined. Third-party deposits will not be accepted and will be refunded within the next 7 working days.",
      alertSAandNGA:
        'If you hold a currency other than U.S. dollars, please exchange the remittance into U.S. dollars before making the remittance. If you remit directly, the exchange rate difference and handling fees incurred at that time will be borne by the customer.',
      options: 'Please select the trading account you would like to fund',
      refInfo:
        'Please include {prefix} your {platform} trading account number in the bank transfer reference field.',
      refInfo2: 'Bank Transfer to IMPS and RTGS only! No UPI transaction.',
      refInfoPrefix: 'F61057 and',
      bussDayInfo: 'Please allow 2-5 business days for the funds to be credited to your account.',
      tip: 'Please ensure your transfer receipt clearly shows your <b>full name</b>, <b>account number</b> and <b>deposit amount</b>.',
    },
    bitwallet: { header: 'Bitwallet', note1: 'We accept payment in USD, AUD, EUR, USC and JPY' },
    astropay: {
      header: 'ASTROPAY',
      desc: 'How to fund your account with ASTROPAY',
      note1: 'We accept payment in all currencies.',
      form: { header: 'ASTROPAY FORM' },
    },
    mobile: {
      header: 'MOBILE PAY',
      desc: 'How to fund your account with Mobile Pay',
      inst1: '1.   Complete the form below and press ‘Submit’',
      inst2: '2.   Scan the QR code to open your mobile banking application',
      inst3: '3.   Follow the prompts on your mobile device to complete your deposit',
      form: { payMethod: 'Payment method:', rate: 'Exchange Rate(USD/CNY):', amt: 'CNY:' },
      formValidation: { cnyAmt: 'CNY Amount cannot be more than $ {limit}' },
    },
    crypto: {
      header: 'CRYPTOCURRENCY DEPOSIT',
      choose: 'Please choose your preferred cryptocurrency',
      proceedQuestion: 'Do you wish to proceed with your <br /><b>{method}</b> deposit?',
      note: 'Please note that we are unable to deposit or withdraw via BUSD, please ensure the address and Cryptocurrency match the chain and currency we accept or you may loss the fund. We shall not be liable for any loss incurred by the errors.',
      note2:
        'Please note that we are unable to deposit or withdraw via BUSDT or ETH, please ensure the address and Cryptocurrency match the chain and currency we accept or you may loss the fund. We shall not be liable for any loss incurred by the errors.',
      remark:
        'Please note that we are unable to deposit or withdraw via BUSDT, please ensure the address and Cryptocurrency match the chain and currency we accept or you may loss the fund.',
      inst2: '2.    You will then be directed to a new window displaying a {method} wallet address',
      inst3:
        '3.    Copy the {method} wallet address and then log in to your personal {method} wallet and transfer your desired amount to the {method} address.',
      inst4:
        'Please note: BITCOIN deposits are processed by B2BinPay at the exchange rate provided at the time of transaction.',
      bitcoin: { button: 'BITCOIN', desc: 'How to fund your account with BITCOIN' },
      usdt: {
        button: 'USDT(OMNI)',
        desc: 'How to fund your account with USDT(OMNI)',
        inst1: '1.    Complete the form below and press ‘Submit’',
        inst2:
          '2.    You will then be directed to a new window displaying a USDT(OMNI) wallet address',
        inst3:
          '3.    Copy the USDT(OMNI) address and then log in to your personal USDT(OMNI) wallet and transfer your desired amount to the USDT(OMNI) address.',
        inst4:
          'Please note: USDT(OMNI) deposits are processed by B2BinPay at the exchange rate provided at the time of transaction.',
        note: 'The current gas fee of ERC20 is significantly higher than usual, therefore the gas fee shall be borne by the client. Alternatively, you may withdraw your funds via TRC20 by providing the coresponding wallet address, or you can select to pay the gas fee of 15 USDT to proceed with the ERC withdrawal. Once your withdrawal request is being submitted, we will proceed with the withdrawal and deduct the corresponding 15 USDT from your final payout amount.',
      },
      usdc: {
        note: 'The current gas fee of ERC20 is significantly higher than usual, therefore the gas fee shall be borne by the client. Alternatively, you may withdraw your funds via TRC20 by providing the coresponding wallet address, or you can select to pay the gas fee of 15 USDC to proceed with the ERC withdrawal. Once your withdrawal request is being submitted, we will proceed with the withdrawal and deduct the corresponding 15 USDC from your final payout amount.',
      },
      usdt_CPS: { button: 'USDT({name})', desc: 'How to fund your account with USDT({name})' },
      form: {
        header_bitcoin: 'BITCOIN FORM',
        header_usdt: 'USDT(OMNI) FORM',
        header_usdt_CPS: 'USDT({name}) FORM',
      },
    },
    skrill: {
      header: 'SKRILL/MONEYBOOKERS DEPOSIT',
      desc: 'How to fund your account with Skrill',
      note1: 'We accept payment in USD, EUR, GBP and CAD',
      note2: 'Accounts can only be funded in the same currency as your MT4 trading account.',
      note3:
        'Skrill will automatically convert funds to your account’s base currency if the incorrect email is used. Fees may apply.',
      note4: 'Deposits must be in the same name as your PU Prime account.',
      form: { header: 'SKRILL FORM', email: 'Skrill/Moneybookers Email' },
      reminder:
        '<li>Please note:</li>\n      <li>* Accounts can only be funded in the same currency as your MT4 trading account. Skrill will automatically convert funds to your account’s base currency if the incorrect email is used. Fees may apply.</li>\n      <li>* Deposits must be in the same name as your {platform} account.</li>',
    },
    sticPay: {
      header: 'STICPAY DEPOSIT',
      desc: 'How to fund your account with STICPAY',
      inst1: '1.   Complete the form below and press ‘Submit’',
      inst2: '2.   Log in to your SticPay account',
      inst3: '3.   Follow the prompts in SticPay account to complete your payment',
      form: { header: 'STICPAY FORM', email: 'SticPay Email' },
    },
    unionPay: {
      header: 'UNION PAY DEPOSIT',
      desc: 'How to find your account with UnionPay',
      inst1: '1.   Complete the form below and press ‘Submit’',
      inst2: '2.   Select bank in bank selection page and login to your online bank',
      inst3: '3.   Follow the instruction and make payment via your Online/Mobile Bank',
      form: { header: 'UNION PAY FORM' },
    },
    unionPayCN: {
      header: 'China Union Pay Transfer',
      desc: 'How to fund your account with China Union Pay Transfer',
      inst1: 'A Payment details page will pop up',
      inst2:
        'You may be asked for membership number while making deposit, please enter your trading account number as Membership number',
      note1:
        'Minimum transaction amount per deposit is 500 CNY and Maximum transaction amount per deposit is 49,999 CNY.',
      note2:
        'Please note that the deposit amount limit may be updated from time to time depending on the market.',
      note3:
        'Please do not copy down the bank beneficiary details being shown as it may be updated from time to time.',
      form: {
        header: 'CHINA UNION PAY FORM',
        holderName: 'UnionPay Card Holders Name:',
        holderNameInfo:
          'Please make sure the name you enter above should be same as your bank card holders name',
      },
    },
    paypal: {
      header: 'PayPal',
      desc: 'How to fund your account with PayPal',
      inst1: '1.   Complete the form below and press ‘Submit’',
      inst2: '2.   Log in to your PayPal account',
      inst3: '3.   Follow the prompts and in PayPal account to complete your payment',
      inst4: 'Please note: We cannot accept payments from a third party',
      form: { header: 'PAYPAL FORM' },
    },
    cardpay: {
      desc: 'How to fund your account with Credit Card',
      inst1: '1.   Complete the form below and press ‘Submit’',
      inst2: '2.   You will then be redirected to another form to enter your card details',
      inst3: '3.   After entering your card details, press ‘Submit’',
      inst4:
        '4.   You will be redirected back to the client portal and your transaction will be finalized',
      form: { header: 'CREDIT CARD FORM' },
    },
    tinkbit: { inst5: '5.   Maximum $1500 USD per deposit' },
    perfectMoney: {
      inst1: '2.   Log in to your Perfect Money account',
      inst2: '3.   Follow the prompts in Perfect Money account to complete your payment',
    },
    indiaBankTransfer: {
      desc1:
        'Once you have made the transfer, please complete the form below and attach a copy of the bank transfer receipt.',
      desc2:
        'Please ensure that clearly shows your full name, account number, and deposit amount so we can process your transaction.',
      bussDayInfo: 'Please allow 1-6 hours for the funds to be credited to your account.',
    },
    localdepositor: {
      depositor: 'Local Depositor',
      inst1: 'Select the depositor that you wish to deposit.',
      inst2: 'Follow the instruction on Local Depositor platform.',
      inst3:
        'You will be redirected back to Client Portal and your transaction will be finalized within 1 working day.',
      withdrawTip: 'For withdrawal rates, please contact your local depositors',
    },
    applePay: {
      payDetails: 'Payment Details',
      inst1: 'You will then be redirected to a pop-up page to confirm the payment details.',
      inst2: "After confirming, press 'ApplePay' button to proceed with the transaction.",
    },
    cps: '{name} Form',
    nodaPayment: {
      imptNotes: 'Important Notes',
      imptNotes1:
        'Third-party deposits are strictly prohibited according to our company policies and the applicable AML/CFT laws. Funds originating from an account not registered in your name will be rejected, which may result in loss of funds or extended processing time for refunds. Any associated additional processing charges will be borne by the account holder.',
      imptNotes2:
        'To ensure swift processing, please verify that the deposit source matches the registered account information with PU Prime. We will only accept deposits from a source registered under your name.',
      imptNotes3:
        'Please note that depositing from a third-party source will result in the restriction of your account. Repeated offenses may lead to the termination of your trading account. It is your responsibility to ensure deposits are made from your own bank account to avoid inconvenience and extra charges.',
      acceptNotes: 'I accept and continue',
    },
    qrcode: {
      step1: 'STEP 1',
      step2: 'STEP 2',
      method1: 'Method 1',
      method2: 'Method 2',
      copyPayment: 'Copy the {payment} wallet address',
      haveMadeThePayment: 'I Have Made The Payment',
      copyAddress: 'Copy Address',
      copyAmount: 'Copy Amount',
      copied: 'Copied',
      note: 'Note',
      scanThisWithYourPhon: 'Scan This With Your Phone',
      enterTheAddressMaually: 'Or Enter The Address Manually',
      logInYourWallwet:
        'Log in to your personal {payment} wallet and transfer the deposit amount to the {payment} address.',
      sumbitNote: 'If you have completed the payment, kindly click "I have made the payment"',
      continueToWalletAddress: 'Continue to Wallet Address',
      usdtNote1:
        '1. Please ensure that the payment amount in {payment} exceeds {number} {currency}. Amounts lower than this threshold will not be credited.',
      usdtNote2:
        '2. {payment} transfers are categorized into Transactions and Internal Transactions. Using Internal Transactions for transfers may result in transactions being lost. Please refrain from using this method.',
      depositSubMissionFailed: 'Deposit submission failed. Please try again later.',
      depositAmount: 'Deposit Amount',
    },
  },
  restrictDeposit: {
    header: 'IMPORTANT NOTIFICATION',
    info: 'Unfortunately we are no longer accepting deposits from clients residing in Canada.',
  },
  withdraw: {
    title: 'Withdraw funds',
    default: {
      example: 'Credit/Debit Card Example',
      accountDetail: 'Account details',
      withdrawalAmount: 'Withdrawal amount',
      disclaimer:
        "<li>Withdrawing funds while you have open positions will affect your margin. Please ensure you close open positions, or ensure you leave enough funds in your account to avoid a margin call.</li>\n      <li>{platform}  strictly doesn't accept payments to any third-party. All funds withdrawn from your trading account must be under the same name as your {platform} client portal account.</li>\n      <li>All initial credit/debit card, e-wallet, moneybookers and bank wire withdrawal requests must be verified for safety and security by the provision of a bank statement, which includes account holder information and bank details.</li>\n      <li>{platform}  reserves the rights to refuse to process a withdrawal request if it has reasonable grounds to believe that the trading account may turn into a negative balance after the withdrawal request is processed.</li>\n      <li>{platform}  reserves the right to refuse a withdrawal request if the trading account is subject to a charge-back or any other on-going investigation.</li>\n      <li>{platform}  is not responsible for any fees that the receiving financial institution or third-party payment providers may charge you for receiving payments.</li>",
      noCardWarn:
        'You don’t have any available card to make withdrawal, please link your card in Withdraw Details first.',
      YourPaymentGreaterThanAvailableBalance: 'Your payment is greater than the available balance',
      ccFail:
        'In order to protect the security of your account, you have reached the maximum attempted deposit transactions and have temporarily suspended your withdrawals. Please contact {email}.',
      selectBA: 'Select Bank Account',
      selectCC: 'Select Credit Card',
      anotherCC: 'Enter credit card manually',
      anotherBA: 'Add bank account',
      remember: 'Remember my account',
      success: 'Your withdrawal request was successful and will be processed shortly',
      fetchTradeAccFailed: 'Fetch Trading Account failed',
      failed: 'Withdraw failed. Please try again later',
      blackList:
        'Unfortunately you are currently unable to submit a withdrawal request. Please contact {supportEmail} for more information',
      equityStop:
        'Unfortunately your withdrawal cannot be processed because it will result in your account’s available margin falling below the stop out level of 100%',
      equityMargin:
        'Unfortunately we are currently unable to process your withdrawal because your account is fully hedged.',
      equityContinue:
        'We have detected you currently have open positions, you may experience stop out after submit withdrawal application, please confirm',
      unfortunate:
        'Unfortunately, we are no longer able to process withdrawals to credit cards. Going forward, withdrawals will be processed to via bank wire to your designated bank account.',
      notEnoughFunds: 'The amount entered exceeds the limit for this card',
      chooseAnother:
        '<p>Maximum withdrawal amount for this card: 0</p>\n      <p>Please choose another card or other payment method</p>',
      checkPosFailed: 'Check Position failed',
      how: 'How did you fund your trading account？',
      alert:
        'Due to AML laws, the method you use to withdraw must be the same as the method used for your deposit. If the withdrawal amount is less than 100 units of your account base currency, you will be charged a 20 unit for handling fee by the bank.',
      NetellerChargeAlert:
        'Neteller withdrawals are limited to $10,000 per transaction, and a 2% fee (capped at $30) applies.',
      agreePolicy: 'I have read and agree to the{link}',
      withdrawPolicy: 'withdraw policy',
      addWithdrawalMethod: 'Add a withdrawal method',
      addSecondaryMethod: 'Add a secondary method',
      withdrawNote:
        "Please note that we will process your withdraw primarily via credit card method. If your credit card amount is used up. We'll ask you to choose another method in order to finalise your withdrawal request.",
      submitYourWithdrawal: 'Submit your withdrawal',
      remainingWithdraw:
        'The remaining withdrawal amount will be split into another credit card or withdrawal method.',
      onlyWithdrawalCcDesc:
        'Only the Credit Card / Local Bank Transfer amount will be fully withdrawn.',
      VerificationCode: 'Enter Verification Code',
      SendCode: 'Send Code',
      phone: 'PHONE',
      evp: 'EVP',
    },
    fasa: {
      field: { accName: 'FasaPay Account Name', accNum: 'FasaPay Account Number' },
      formValidation: {
        accNameReq: 'FasaPay Account Name is required',
        accNumReq: 'FasaPay Account Number is required',
      },
      fasaPayChargeAlert: 'FasaPay charges 0.5% transaction fee for every withdrawal.',
    },
    neteller: {
      field: { email: 'Neteller Email' },
      formValidation: { emailReq: 'Neteller Email is required' },
      netellerChargeAlert:
        'Neteller charges {percentageFee}% transaction fee for every withdrawal.',
    },
    vload: {
      field: { email: 'Vload Registered Email' },
      formValidation: { emailReq: 'Vload Registered Email is required' },
    },
    bitcoin: {
      field: { address: 'Bitcoin Wallet Address' },
      formValidation: {
        addressReq: 'Bitcoin Wallet Address is required',
        addressFormat: "Your wallet address should start with ‘1' or ‘3’ or 'bc1’",
        addressLength: 'Bitcoin wallet address must be between 26 and 42 characters',
      },
    },
    eth: {
      field: { address: 'ETH Wallet Address' },
      formValidation: {
        addressReq: 'ETH Wallet Address is required',
        addressFormat: 'Your ETH wallet address is invalid',
        addressLength: 'ETH wallet address must be between 26 and 42 characters',
      },
    },
    usdc: {
      field: { address: 'USDC Wallet Address' },
      formValidation: {
        addressReq: 'USDC Wallet Address is required',
        addressFormat: 'Your USDC wallet address is invalid',
        addressLength: 'USDC wallet address must be between 26 and 42 characters',
      },
    },
    usdt: {
      field: { address: 'USDT Wallet Address', chain: 'Chain' },
      formValidation: {
        addressReq: 'USDT Wallet Address is required',
        chain: 'Chain is required',
        walletFormatIncorrect: 'Incorrect {type} wallet address format',
        trc20WalletFormatIncorrect: "Your wallet address should start with 'T'",
        erc20WalletFormatIncorrect: "Your wallet address should start with '0x'",
      },
    },
    bitwallet: {
      field: { email: 'Bitwallet Email' },
      formValidation: { emailReq: 'Bitwallet Email is required' },
    },
    astropay: {
      field: { accountNumber: 'Astropay Account' },
      formValidation: { accountNumberReq: 'Astropay Account is required' },
    },
    paypal: {
      field: { email: 'Paypal Email' },
      formValidation: { emailReq: 'Paypal Email is required' },
    },
    skrill: {
      field: { email: 'Skrill Email' },
      formValidation: { emailReq: 'Skirll Email is required' },
      skrillChargeAlert: 'Skrill charges {percentageFee}% transaction fee for every withdrawal.',
    },
    sticpay: {
      field: { email: 'SticPay Email' },
      formValidation: { emailReq: 'SticPay Email is required' },
    },
    perfectMoney: {
      field: { email: 'Perfect Money email', account: 'Perfect Money Account' },
      formValidation: { emailReq: 'Perfect Money email is required' },
    },
    transfer: {
      info1:
        '{platform} will waive the bank fee charges on first withdrawal of the month. Additional withdrawals within the same month will incur a bank fee of 20 units of your withdrawal currency (For USC/HKD/JPY, the fee will be equivalent to 20 USD)',
      info2:
        'Please ensure Bank Beneficiary Name fill in exactly with Bank statement (abbreviation prohibited, fill in full name according to the initial on the statement)',
      info3:
        "Bank account is preferred with IBAN format, if you're not from EU region, please fill in exactly with bank statement",
      label: {
        upload: 'Please upload a copy of your bank statement (current to the last 3 months)',
        upload2:
          'Please make sure that the statement date or the last transaction date is from the current month to the past three months. If it exceeds the timeframe, kindly re-upload the statement.',
      },
      formValidation: { upload: 'Please upload a bank statement', emailReq: 'Email is required' },
      minAmount: 'Minimum withdrawal amount is 100 {currency}',
    },
    otherMethod: {
      withdrawalMethod: 'Withdraw Method',
      manageWithdrawalDetails: 'Manage withdrawal details',
      verificationCode:
        'Verification code has been sent to {email}. Please contact {supportEmail} if you need further assistance.',
      withdrawalFees: '{percentageFee}% Withdrawal Fees: {calculatedAmount} {currency}',
      amountsExceeding:
        'There will be a withdrawal fee for amounts exceeding your net deposit in cryptocurrency.',
    },
    LBTchannelsNote:
      'Note:\n  Bank Account Name/Bank Beneficiary Name must match the name on your bank\n  account.',
  },
  transfer: {
    transferAmountIsGreaterThanAvailableBalance:
      'Your transfer amount is greater than the available balance',
    creditWarn:
      'We are unable to process your funds transfer from the account selected due to your account containing promotional credit.',
    blackListWarn:
      'Transfer of funds from your MAM account can result in the liquidation of managed positions. Please contact {mailTo} if you wish to transfer funds from this account.',
  },
  changePw: {
    header: 'CHANGE PASSWORD',
    field: {
      currPw: 'Current Password',
      newPw: 'New Password',
      confirmPw: 'Confirm New Password',
      withdrawText:
        'For security purposes, withdrawals from your account will be temporarily disabled for {day} day after you change your login password.',
      title: 'Change Login Password',
    },
    succ: 'Your password has been updated. You may now use your new password to access Client Portal.',
  },
  promotion: {
    noDepositBonus: {
      tnc: 'Terms and Conditions',
      faceVerificationReview: 'Face Verification in Review',
      faceVerificationReviewDesc:
        'This process may take approximately 3 minutes to 24 hours to complete.',
      bonusHistory: 'Bonus History',
      tradeNow: 'Trade Now',
      filetip: 'Please upload file',
      IDVerification: 'ID Verification',
      POAVerification: 'Address Verification',
      faceVerification: 'Face Verification',
      claimDate: 'Claim Date',
      tradingAccount: 'Trading Account',
      credit: 'Credit Amount',
      verifyNow: 'Verify Now',
      poftit1: 'ID must clearly show your full name and date of birth',
      uploadTemplate: {
        good: 'Good',
        blurry: 'Blurry',
        edgeMissing: 'Edge Missing',
        reflective: 'Reflective',
      },
      errorMessageFor1308:
        'The maximum withdrawal limit is ${amount}. Any additional balance exceeding this limit will be removed upon withdrawal. Please make a deposit to retain your balance. ',
      errorMessageFor1307:
        'Your withdrawal limit of ${amount} has been reached. Please make a deposit to remove the withdrawal restriction.',
      errorMessageFor1306lots:
        'Withdrawal is currently unavailable. Please complete at least {amount} lot(s) to proceed with withdrawal.',
      errorMessageFor1306trades:
        'Withdrawal is currently unavailable. Please complete at least {amount} trade(s) to proceed with withdrawal.',
      pofDesc:
        'Please ensure that your face is fully visible, and that you are holding the same ID used for your ID verification in the face verification photo. ',
    },
    common: { tnc: 'Terms and Conditions' },
    clickToGet: 'Just click and get your $50 Bonus',
    apply: 'APPLY NOW',
    start: 'Start Now',
    tnc: "\n    <li> 1. This offer is available for 30 days.</li>\n    <li> 2. This offer is available to all clients(Existing & New) who have a live account but never made a deposit before.</li>\n    <li> 3. This offer is available to new clients in the following areas: France, United Kingdom, Taiwan, Italy, Canada, Belgium, Switzerland, HongKong, Nigeria, Netherlands, Japan, Luxembourg, Malta, Reunion, Germany, Martinique, Spain, Latvia, Poland, Côte d'Ivoire, Denmark, Brazil, New Zealand, Portugal, Isle Of Man, South Africa, Turkey, Romania, Guadeloupe, Austria, Ireland, French Guiana, Swaziland, Argentina, Ukraine, Greece, Sweden, Monaco, Hungary, Haiti, Finland, Bolivia, Chile, Costa Rica, Croatia, Czech Republic, Estonia, Mexico, Norway, Philipine.</li>\n    <li> 4. This offer is eligible on Standard Accounts and Islamic Standard Accounts only。</li>\n    <li> 5. Getting bonus and/or trading bonus funds ON TWO OR MORE ACCOUNTS with the same IP-address/ID/passport is prohibited. Irrespective of the number of trading accounts that a Client may hold with PU Prime, the promotion is applicable for one account only.</li>\n    <li> 6. Clients will receive 50 USD (or equivalent) credits after opt-in to this offer successfully and there is no requirement to make a deposit.</li>\n    <li> 7. The credit amounts cannot be withdrawn. However, you can still withdraw part of the profit earned from your trading.</li>\n    <li> 8. You must close all open positions before applying for withdrawal. Once you opt-to withdraw, the bonus will be deducted from the account;</li>\n    <li> 9. PU Prime reserves the right to change the conditions or refuse to grant a bonus at any time.</li>",
    selectDesp:
      '* Please select the account in which you would like to join the No Deposit Bonus activity.',
    descp1: 'Do you want to start trading without committing an investment from the onset?',
    descp2:
      'Apply your $50 bonus to start making a profit<br> without risking any of your funds at all.',
    tnc2: '<li> The minimum amount you are able to withdraw is 100 USD(or equivalent), and the maximum amount to withdraw is 200 USD(or equivalent). Any profits made on top of this capped amount will be removed when we process your withdrawal request.</li>',
    tnc3: '\n    <li> 1. This promotion is valid for a year since you participant.</li>\n    <li> 2. When the promotion expires, PU Prime will reset any remaining deposit bonus (es) redeemed under this promotion to Zero without further notification to the clients therefore clients should make sure they have enough margin in their account to prevent the trades from getting liquidated.</li>\n    <li> 3. The “50% DEPOSIT BONUS” is available ONLY for Standard Account, Islamic Standard Account, Prime Account, Islamic Prime Account.</li>\n    <li> 4. To claim your 50% deposit bonus, please click the "Activate" button on this page to activate the promotion before making any deposits.</li>\n    <li> 5. For your first deposit under the promotion, we will credit 50% of the deposited amount as a bonus into your account, up to a maximum of 500 USD.</li>\n    <li> 6. For the next deposit, we will credit 20% of the deposited amount as a bonus into your account, up to a maximum of 9,500 USD.</li>\n    <li> 7. Total bonus available to claim: 10,000 USD.</li>\n    <li> 8. The “50% DEPOSIT BONUS” applies to all clients who open real (meaning not demo) Account with PU Prime in accordance with the rules set herein and granted deposit bonuses may be used for trading purposes only. Any profits generated can be withdrawn at any point in time, however, any withdrawal of funds will result in the proportional removal of your deposit bonus. Transferring funds between your MT4 trading accounts is also considered as withdrawal.</li>\n    <li> 9. Internal transfers are not allowed between the trading accounts that have redeemed the “50% DEPOSIT BONUS”.</li>',
    header: 'PROMOTION TERMS AND CONDITIONS',
    successful: 'Submission Successful',
    failed: 'Submission Failed',
    confirmMsg: "By clicking the “Confirm” button, you'll be opted out of the promotion",
    defaultBanner: {
      banner: { title: '<h2>reliable broker</h2><h4>serving 120,000+ customers globaly</h4>' },
    },
    appPromotionInfo: {
      buttonDesc: 'Click the button below to participate in the activity',
      buttonTitle: 'Participate Now',
      claimTitle: 'How It Works?',
      eligibilityTitle: 'Eligibility',
      tnc: '*Terms and conditions apply. {link} to view',
      link: 'Click here',
    },
    referFriends: {
      intro: 'Finish 3 Steps to get your {amount}',
      step1: 'STEP 1',
      step1Descp: 'Open Account Successfully',
      step2: 'STEP 2',
      step2Descp: 'Deposit Successfully',
      step3: 'STEP 3',
      step3Descp: 'Trading Volume Completed',
      totalEanringTitle: 'Total earnings <br/>(USD or equivalent)',
      totalEarningTip: '* Credit of bonus into your account is subject to approval.',
      peroidForAudit: '* Processing will take up to 5 days.',
      redeem: 'Redeem',
      intro2: 'Meanwhile，you can also refer your friends',
      shareLink: 'Share your personalized referral link',
      shareLinkDescp:
        'Click the "share" button to get your unique link and copy it to various social media.',
      share: 'Share',
      total: 'Total redeemed referrals',
      social: 'Share your link through Various Social Media',
      clients: 'CLIENTS',
      table: {
        referralsNo: 'Number of referrals',
        name: 'Name',
        country: 'Country',
        openAccount: 'Open Account Successfully',
        despositSuccess: 'Deposit Successfully',
        volumeCompleted: 'Trading Volume Completed',
      },
      totalRedeemableTitle: 'Total redeemable earnings  <br/>(USD or equivalent)',
      totalRedeemableTip: '* Credit of bonus into your account is subject to approval.',
      referrer: 'Referrer',
      referral: 'Referral',
      dialog: {
        confirmQ: 'Redeem your earnings',
        confirmQ2: 'The total amount converted into your base currency is {amount} {currency}.',
        redeem: 'REDEEM NOW',
        agree: '* By clicking redeem now, you agree with all the{link}.',
        overBonus:
          'Our system has detected that you have reached our maximum amount. For claims, please contact our customer support.',
      },
      banner: {
        dec1: 'Get',
        dec2: 'For Every Friend Referral',
        dec3: 'Get',
        dec4: 'Your Referred Friend',
      },
    },
    depositBonus: {
      banner: { title: 'Claim Your 50% Deposit Bonus!<br>Get Up to $10,000' },
      depositBonus: 'Claim your bonus & boost your tradings',
      title:
        'Want to increase your account margin and boost your trading volumes? Then fund your account today, and claim the 50% bonus from us!',
      fund: 'Fund your account',
      tnc: '*Term and condition applies, {link} to view',
      link: 'click here',
      bonusHistory: 'Bonus History',
      overBonus:
        '* You have reached the maximum of ${totalLimit} bonus credits. Additional deposits do not qualify for bonus credits.',
      opt: 'Opt-in & Opt-out Confirmation',
      tick: 'Please uncheck the box if you would like to opt-out or tick the box if you would like to opt-in.',
      saveChange: 'Save Changes',
      depositDate: 'DEPOSIT DATE',
      credit: 'CREDIT',
      optIn: 'Opt-in Successfully',
      optOut: 'Opt-out Successfully',
      optInDescription: 'Please click the button below to opt in the 50% deposit bonus',
      optOutDescription: 'Please click the button below to opt out the 50% deposit bonus',
      activate: 'ACTIVATE',
      signOut: 'SIGN OUT',
      banner1: 'Claim Your 50% Deposit Bonus',
      claimDescription:
        '<p>How to claim</p>\n      <li>1.\tActivate before you deposit </li>\n      <li>2.\tFund your trading account to qualify for the reward </li>\n      <li>3.\tReward will fund to your account automatically </li>\n      <li>4.\tEnjoy your trading </li>',
      eligibleDescription:
        '<p>Eligibility</p>\n      <li>1.\tThe reward must be activated before you deposit </li>\n      <li>2.\tWhen a client in the following countries deposits via cryptocurrency or e-wallet, he will not receive the deposit bonus ( Malaysia, UAE , Taiwan, Turkey, Saudi Arabia ) </li>\n      <li>3.\tThe reward is ONLY available for Standard Account, Islamic Standard Account, Prime Account, Islamic Prime Account </li>\n      <li>4.\tYou can only apply for the 50% ratio deposit bonus once, and all subsequent deposits are only calculated according to the 20% ratio </li>',
      optInDialog: 'Congratulations!{br}You have successfully activated the 50% deposit bonus.',
      optOutDialog: 'You have successfully opted out for the 50% deposit bonus.',
    },
    deposit20Bonus: {
      fund: 'Fund your account',
      tnc: '*Term and condition applies, {link} to view',
      link: 'click here',
      bonusHistory: 'Deposit History',
      overBonus:
        '* You have reached the maximum of ${totalLimit} bonus credits. Additional deposits do not qualify for bonus credits.',
      opt: 'Opt-in & Opt-out Confirmation',
      depositDate: 'DEPOSIT DATE',
      credit: 'CREDIT',
      depositAmt: 'Deposit Amount',
      optIn: 'Opt-in Successfully',
      optOut: 'Opt-out Successfully',
      optInDescription:
        'Please click the button below to opt in to the 20% Deposit Bonus promotion',
      optOutDescription: 'Please click the button below to opt out the 20% Deposit Bonus promotion',
      activate: 'ACTIVATE',
      signOut: 'SIGN OUT',
      banner1: 'CLAIM YOUR 20% DEPOSIT BONUS',
      claimDescription:
        '<p>How to claim</p>\n      <li>1.\tActivate before you deposit. </li>\n      <li>2.\tFund your trading account to qualify for the reward. </li>\n      <li>3.\tAny bonuses will be credited to your account automatically. </li>\n      <li>4.\tEnjoy your trading! </li>',
      eligibleDescription:
        '<p>Eligibility</p>\n      <li>1.\tThe promotion must be activated before you make a deposit. </li>\n      <li>2.\tThis promotion is ONLY available to customers in Vietnam.</li>\n      <li>3.\tThe bonus is ONLY available for Standard Accounts, Islamic Accounts, Prime Accounts, and Islamic Prime Accounts.</li>\n      <li>4.\tOnly new deposits made during the promotion period are eligible for the 20% Deposit Bonus. This does not include fund transfers between accounts.</li>\n      <li>5.\tThe total credit is capped at $10,000 per client.</li>',
      optInDialog:
        'Congratulations!{br}You have successfully activated the Vietnam 20% deposite bonus. ',
      optOutDialog: 'You have successfully opted out for the Vietman 20% deposit bonus.',
      agreeTnc:
        '\n      <li> 1. This promotion is valid for a year since you participant.</li>\n      <li> 2. When the promotion expires, PU Prime will reset any remaining deposit bonus (es) redeemed under this promotion to Zero without further notification to the clients therefore clients should make sure they have enough margin in their account to prevent the trades from getting liquidated.</li>\n      <li> 3. The “Vietnam 20% DEPOSIT BONUS” is available ONLY for Standard Account, Islamic Standard Account, Prime Account, Islamic Prime Account.</li>\n      <li> 4. To claim your 20% deposit bonus, please click the "Activate" button on this page to activate the promotion before making any deposits.</li>\n      <li> 5. For your deposit, we will credit 20% of the deposited amount as a bonus into your account, up to a maximum of $10000USD（or equivalent) credit bonus.</li>\n      <li> 6. The “Vietnam 20% DEPOSIT BONUS” applies to all clients who open real (meaning not demo) Account with PU Prime in accordance with the rules set herein and granted deposit bonuses may be used for trading purposes only. Any profits generated can be withdrawn at any point in time, however, any withdrawal of funds will result in the proportional removal of your deposit bonus. Transferring funds between your MT4 trading accounts is also considered as withdrawal.</li>\n      <li> 7. Internal transfers are not allowed between the trading accounts that have redeemed the “Vietnam 20% DEPOSIT BONUS”.</li>',
      headerText: 'Terms and Conditions',
      tradingAcc: 'Trading Account',
      banner: {
        title:
          'For Vietnamese Customers Only:</br> Get <span class="bg_yellow">20%</span> of Your Deposit </br>in Credit Bonuses',
      },
    },
    depositMY20Bonus: {
      fund: 'Fund your account',
      tnc: '*Term and condition applies, {link} to view',
      link: 'click here',
      bonusHistory: 'Deposit History',
      overBonus:
        '* You have reached the maximum of ${totalLimit} bonus credits. Additional deposits do not qualify for bonus credits.',
      opt: 'Opt-in & Opt-out Confirmation',
      depositDate: 'DEPOSIT DATE',
      credit: 'CREDIT',
      depositAmt: 'Deposit Amount',
      optIn: 'Opt-in Successfully',
      optOut: 'Opt-out Successfully',
      optInDescription:
        'Please click the button below to opt in to the 20% Deposit Bonus promotion',
      optOutDescription: 'Please click the button below to opt out the 20% Deposit Bonus promotion',
      activate: 'ACTIVATE',
      signOut: 'SIGN OUT',
      banner1: 'CLAIM YOUR 20% DEPOSIT BONUS',
      claimDescription:
        '<p>How to claim</p>\n    <li>1.\tActivate before you deposit. </li>\n    <li>2.\tFund your trading account to qualify for the reward. </li>\n    <li>3.\tAny bonuses will be credited to your account automatically. </li>\n    <li>4.\tEnjoy your trading! </li>',
      eligibleDescription:
        '<p>Eligibility</p>\n    <li>1.\tThe promotion must be activated before you make a deposit. </li>\n    <li>2.\tThis promotion is ONLY available to customers in Malaysia.</li>\n    <li>3.\tThe bonus is ONLY available for Standard Accounts, Islamic Accounts, Prime Accounts, and Islamic Prime Accounts.</li>\n    <li>4.\tOnly new deposits made during the promotion period are eligible for the 20% Deposit Bonus. This does not include fund transfers between accounts.</li>\n    <li>5.\tThe total credit is capped at $10,000 per client.</li>\n    <li>6.  When clients make deposits via cryptocurrency or e-wallet, they will NOT receive the credit bonus.',
      optInDialog:
        'Congratulations!{br}You have successfully activated the Malaysia 20% deposite bonus. ',
      optOutDialog: 'You have successfully opted out for the Vietman 20% deposit bonus.',
      agreeTnc:
        '\n    <li> 1. This promotion is valid for a year since you participant.</li>\n    <li> 2. When the promotion expires, PU Prime will reset any remaining deposit bonus (es) redeemed under this promotion to Zero without further notification to the clients therefore clients should make sure they have enough margin in their account to prevent the trades from getting liquidated.</li>\n    <li> 3. The “Malaysia 20% DEPOSIT BONUS” is available ONLY for Standard Account, Islamic Standard Account, Prime Account, Islamic Prime Account.</li>\n    <li> 4. To claim your 20% deposit bonus, please click the "Activate" button on this page to activate the promotion before making any deposits.</li>\n    <li> 5. For your deposit, we will credit 20% of the deposited amount as a bonus into your account, up to a maximum of $10000USD（or equivalent) credit bonus.</li>\n    <li> 6. The “Malaysia 20% DEPOSIT BONUS” applies to all clients who open real (meaning not demo) Account with PU Prime in accordance with the rules set herein and granted deposit bonuses may be used for trading purposes only. Any profits generated can be withdrawn at any point in time, however, any withdrawal of funds will result in the proportional removal of your deposit bonus. Transferring funds between your MT4 trading accounts is also considered as withdrawal.</li>\n    <li> 7. Internal transfers are not allowed between the trading accounts that have redeemed the “Malaysia 20% DEPOSIT BONUS”.</li>',
      headerText: 'Terms and Conditions',
      tradingAcc: 'Trading Account',
      banner: {
        title:
          'For Malaysian Customers Only:</br> Get <span class="bg_yellow">20%</span> of Your Deposit </br>in Credit Bonuses',
      },
    },
    dynamicDepositBonus: {
      optOutDescription: 'Please click the button below to opt out to the Deposit Bonus promotion.',
      optInDescription: 'Please click the button below to opt in to the Deposit Bonus promotion.',
      optInDialog: 'Congratulations!{br}You have successfully activated the Deposit Bonus. ',
      optOutDialog: 'You have successfully opted out for the Deposit Bonus.',
      bannerTitle: 'Get Up to ${amount} Deposit Bonus',
      bannerDesc: 'To Continue Trading Even When Your Balance <b>Hits Zero</b>',
    },
    tradingBonus: {
      banner1: 'Claim Your 100% Cash Rebate',
      optInDescription:
        'Please click the button below to activate and participate in the activity.',
      optOutDescription: 'Please click the button below to sign out.',
      activate: 'ACTIVATE',
      signOut: 'SIGN OUT',
      claimTitle: 'HOW TO CLAIM',
      claimDescriptions: [
        'Activate before you deposit',
        'Fund your trading account to qualify for the reward',
        'This cash rebate amount will appear on your Client Portal automatically',
        'Enjoy your trading and earn more value with every trade!',
      ],
      eligibilityTitle: 'ELIGIBILITY',
      eligibleDescriptions: [
        'The promotion must be activated before you deposit.',
        'The bonus is ONLY available for Standard Account, Islamic Standard Account',
        'Clients can ONLY apply for the 100% cash rebate on their first deposit (≥1000 USD Or equivalent) during the promotion period.',
        'The trading cycle of this promotion is 30 days.  After expiration, the bonus will be automatically removed from the trading account and no rebate can be generated for any transaction.',
        'Rebates are ONLY based on volume traded on forex, gold, silver, and oil products and rebates earned are applied when a trade is closed (A trade must be closed for the rebate to be generated.)',
      ],
      fund: 'Fund your account',
      tnc: '*Term and condition applies, {link} to view',
      link: 'click here',
      optInDialog:
        'Congratulations!{br}You have successfully activated and participated in the 100% cash rebate promotion.',
      optOutDialog: 'You have successfully signed out of the 100% cash rebate promotion.',
      acountDialogTitle:
        '*PLEASE SELECT THE ACCOUNT WHICH YOU WOULD LIKE TO JOIN THE CASH REBATE ACTIVITY.',
      tradingRewardHistory: 'TRADING REWARD HISTORY',
      endedTip:
        'Once you submit the transfer request, the 100% Promotion will be ended and all your remaining bonus will be removed.',
      banner: { title: 'Claim Your<br> <span class="bg_blue">100%</span> Cash Rebate' },
      bonusTable: {
        depositDate: 'DEPOSIT DATE',
        tradingAccount: 'TRADING ACCOUNT',
        currency: 'CURRENCY',
        amount: 'AMOUNT',
        BONUS: 'BONUS',
      },
      tradingTable: {
        date: 'DATE',
        tradingAccount: 'TRADING ACCOUNT',
        currency: 'CURRENCY',
        volumeLot: 'VOLUME/LOT',
        amount: 'AMOUNT',
      },
    },
    cryptoCashback: {
      banner: {
        bannerContext:
          '\n          <span class="title">Double Your Savings</span></br>\n          <span class="desc">Get up to $10,000 cashback and ZERO swap fees</span>\n        ',
        activityBannerContext:
          '\n          <span class="title">Double Your Savings</span></br>\n          <span class="desc">Shorting the downtrend or buying the dip?</span></br>\n          <span class="desc">Get up to $10,000 in cashback when you trade cryptocurrency with PU Prime!</span>\n        ',
      },
      active: {
        title: 'Activate Cryptocurrency Cashback',
        desc: 'Please click the button below to activate and participate in the activity',
      },
      deactive: {
        title: 'Deactivate Cryptocurrency Cashback',
        desc: 'Please click the button below to deactivate and withdraw from the Cryptocurrency Cashback promotion.',
      },
      button: {
        active: 'Activate',
        deactive: 'Deactivate',
        submit: 'Submit',
        fund: 'Fund your account',
      },
      claimTitle: 'HOW TO CLAIM',
      claimDescriptions: [
        'Activate the promotion before making a deposit.',
        'Fund your trading account to meet the deposit requirements（≥ 500 USD).',
        'Cashback per lot will depend on your total net deposit amount (see T&Cs).',
        'Enjoy trading and get more value with every trade!',
      ],
      eligibilityTitle: 'ELIGIBILITY',
      eligibleDescriptions: [
        'This promotion must be activated before you make a deposit.',
        'This promotion is only available for Standard Accounts and Islamic Standard Accounts. If a client has multiple eligible trading accounts, only ONE eligible account can be selected to participate in this promotion.',
        'This promotion lasts 30 days from the point of your first successful deposit after activation.',
        'Cashback is based on volume traded on all cryptocurrency products except the following: ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU.',
        'The total cashback is capped at $10,000 per client.',
      ],
      tnc: '*Term and condition applies, {link} to view',
      link: 'click here',
      message: {
        congratulate: 'Congratulations!',
        congratulateContext:
          'You have successfully activated the Cryptocurrency Cashback promotion.',
        signedOutPromo: 'By Clicking the Confirm Button, you will be signed out the promotion.',
        deactivePromo:
          'You have successfully deactivate from the Cryptocurrency Cashback Promotion.',
      },
      dialog: {
        selectAccountContext: 'Please select the account which you would like to join the activity',
        tnc: 'Terms and conditions',
        tncContext: {
          tnc1: '1. After you activate this promotion, you also have to deposit funds and trading cryptocurrency as required to receive the corresponding cashback.',
          tnc1desc:
            '* Only new deposits made during the promotion period are valid for the promotion. This excludes internal transfers, refer-a-friend bonuses, and cash adjustments.',
          tnc2: '2. Eligible Clients (both new and existing clients), who meet the criteria set forth herein are able to receive cashback, equal to:',
          tnc3: '3. The eligible products: All cryptocurrency products except ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU.',
          tnc4: '4. The effective trading volume: The newly opened and closed orders during the promotion period. Positions open for less than 5 minutes, locked positions, and hedged positions do not count as valid trades for this promotion.',
          tnc5: '5. Promotion Duration: 30 Days after the participants make the first succeed deposit.',
          tnc5desc:
            '* If the client activates the promotion and has not made a deposit, the promotion will also automatically end after 30 days.',
          tnc6: '6. This offer cannot be used in conjunction with any other offer. （Except “You Can Be a trader too campaign”,Refer A Friend and 50% deposit bonus)',
        },
        agreeTnc: 'I have read and agree to the Terms and Conditions.',
      },
      field: {
        netDeposit: 'Net Deposit',
        usddesc: 'USD (or equivalent currency)',
        cashback: 'Cashback',
        standardLot: 'standard lot',
        date: 'Date',
        depositAmount: 'Deposit amount',
        withdrawAmount: 'Withdraw amount',
        netDepositAmount: 'Net deposit amount',
        rebateLot: 'Rebate / Lot',
        tradingDate: 'Trading date',
        tradingLot: 'Trading lot',
        cashRebate: 'Cash rebate',
      },
      depositHistory: 'DEPOSIT HISTORY',
      tradingRewardHistory: 'TRADING REWARD HISTORY',
    },
    xmasMakeAWish: {
      banner: {
        bannerContext:
          '\n          <div class="title">Win Prizes Worth Up To $5,000!</div>\n          <div class="desc">At our sure-win lucky draw</div>\n        ',
      },
      active: {
        title: 'ACTIVATE MAKE A WISH PROMO',
        desc: 'Please click the button below to activate in to the Xmas Make a Wish promotion.',
      },
      deactive: {
        title: 'XMAS Make A Wish Promo',
        desc: 'Please click the button below to deactivate in to the Xmas Make a Wish promotion.',
      },
      button: {
        active: 'Activate',
        deactive: 'Deactivate',
        submit: 'Submit',
        fund: 'Fund Your Account',
        enterLuckyDraw: 'Enter Lucky Draw',
        redeemed: 'Redeemed',
      },
      claimTitle: 'HOW TO CLAIM',
      claimDescriptions: [
        'Activate the promotion before making a deposit.​',
        'Fund your trading account to meet the deposit requirements for the gift you want, then make the corresponding number of trades required. (see gift table below).',
        'When eligible, you will get a chance at winning one of the gifts in your corresponding tier. (see gift table below).',
        'Click on the Enter Lucky Draw button to roll for your gift.',
        'Gifts won will be recorded, and all gifts will be distributed 7 days after the end of the promotion.',
      ],
      eligibilityTitle: 'ELIGIBILITY',
      eligibleDescriptions: [
        'Eligible account types: This offer is ONLY open to Standard accounts and Islamic Standard Accounts, both in MT4/MT5.',
        'If a client has multiple Standard Accounts, they can only select one Standard Account to participate in this promotion with.',
        'Eligible countries: All except mainland China',
        'Deposits and trades must be made during the promotion period to be considered valid.',
        'Valid trading volume: Forex, Gold, Silver, and Crude Oil positions held for more than 5 minutes.',
      ],
      moreDetail: 'For more details about the prizes distribution, view the {prize}.',
      prize: 'prize list here',
      tnc: '*Term and condition apply. Click {link} to view',
      link: 'here',
      luckyDraw: {
        description: [
          'Hit the Lucky Draw button to win <span class="font-bold">one</span> of the above prizes!',
          'Not the gifts you are looking for?  Continue making deposits and trading to hit the tier that you want.',
        ],
        mainTitle: 'Your Current Gift Tier:',
        commingSoon: {
          title: 'Make a Wish',
          content: 'Coming soon in mid-November to enter the lucky draw.',
        },
        noChanceToDraw: {
          title: 'Deposit Now',
          content: 'Fund your account with a min. $200 deposit to enter the lucky draw.',
        },
        endPromotion: {
          title: 'Lucky Draw Has Ended',
          content:
            'Thank you for your participation! Make sure that you have redeemed your gifts by 31st March 2023.',
        },
      },
      currentDepositLots: 'CURRENT DEPOSIT / LOTS TRADED',
      transactionHistory: 'TRANSACTION HISTORY',
      transactionHistoryDesc:
        '*Deposit transaction is the amount deposited and deposit value deducted from entering the lucky draw.',
      giftRedemption: 'GIFT REDEMPTION',
      giftRedemptionDesc: [
        'We will contact you again for the physical gift(s) redemption.',
        '*All prizes can only be redeemed from 2nd February 2023 till 2nd March 2023.',
      ],
      field: {
        depositAmount: 'Deposit Amount',
        lotsTraded: 'Lots Traded',
        date: 'Date',
        type: 'Type',
        deposit: '*Deposit',
        tradingLots: 'Trading Lots',
        prizeWon: 'Prize(s) Won',
        creditBonus: 'Credit Bonus',
        cashVoucher: 'Cash Voucher',
        physicalRewards: 'Physical Rewards',
        depositTradingVolume: 'Deposit / Trading Lots',
        gifts: 'Gifts （USD）',
        accountID: 'Account ID',
        current: 'Current',
        luckyDrawDeposit: 'Deposit',
      },
      dialog: {
        selectAccountContext:
          'Please select the account which you would like to receive your credit bonus in (MT4/MT5 account only)',
        tnc: 'Terms and conditions',
        tncContext: {
          tnc1: '1. Clients need to successfully activate this promotion within their client portal first, and then deposit funds and trade as required to receive the corresponding gifts.',
          tnc2: '2.\tEffective trading volume: Standard lots of currency pairs, gold, silver, crude oil spot, and crude oil futures. Orders must be opened and closed during the promotion period. Positions open for less than 5 minutes, locked positions, and hedged positions do not count as valid trades for this promotion.',
          tnc3: '3.\tStatistics and order volume are drawn from MT4 time 01/11/2022-31/01/2023.',
          tnc4: '4.\tIf the gift cannot be purchased due to force majeure factors such as the product being off the shelves or out of stock, the customer can apply to receive cash equivalent to said gift’s market price. This price is based on the U.S. dollar price (or equivalent currency).',
          tnc5: '5.\tThis offer cannot be used in conjunction with any other offer. （100 trading bonus，20% exclusive bonus, 50% deposit bonus，VN 20% deposit bonus).',
        },
        agreeTnc: 'I have read and agree to the Terms and Conditions.',
        prizeList: 'Prize List',
        luckyDrawTransaction: 'Lucky Draw Transaction',
        xmasGift: 'Congratulations on your christmas gift!',
      },
      message: {
        congratulate: 'Congratulations!',
        congratulateContext: 'You have successfully activated the Xmas Make a Wish Promotion',
        signedOutPromo: 'By Clicking the Confirm Button, you will be signed out of the promotion!',
        deactivePromo: 'You have successfully opted out for the Xmas Make a Wish Promotion.',
        redeemAmount: 'The amount will be credited into your account!',
      },
      tableContent: { luckyDraw: 'Lucky Draw', depositTrade: 'Deposit / Trade' },
    },
    nfp: {
      title: 'PU Prime Non-Farm Payroll Promotion',
      desc: 'Nonfarm Payrolls (NFPs) are an essential economic indicator related to employment in the United States. Based on changes in employment, NFP data can act as a stimulus for foreign exchange trading. Technical analysis can be employed in the NFP report using 5 or 15-minute chart intervals. NFP reports are typically released on the first Friday of each month.',
      buttonDesc: 'Click the button below to participate in the activity',
      buttonTitle: 'Participate Now',
      claimTitle: 'How It Works?',
      claimDescriptions: [
        'PU Prime APP NFP Promotion allows users to predict the next month‘s NFP figure and will be rewarded in cash vouchers totaling up to $500 to the Top 10 winners with the closest prediction.',
      ],
      eligibilityTitle: 'Eligibility',
      eligibleDescriptions: [
        'Users must download the PU Prime APP to participate in this promotion.',
        'Cash Prizes will ONLY be available for Standard account & Islamic Standard account.',
        'Trading requirements for cash prizes redemption must be made during the promotion period to be considered valid.',
      ],
      tnc: '*Terms and conditions apply. {link} to view',
      link: 'Click here',
    },
    fgp: {
      title: 'Unlock 13% p.a. Funds Growth',
      desc: ' Join our active trader program and earn up to <span style="color: #014ABD">13%</span> p.a. on your account balance today.',
      buttonDesc: 'Click the button below to participate in the activity',
      buttonTitle: 'Opt In Now',
      claimTitle: 'How It Works?',
      claimDescriptions: [
        'PU Prime APP NFP Promotion allows users to predict the next month‘s NFP figure and will be rewarded in cash vouchers totaling up to $500 to the Top 10 winners with the closest prediction.',
      ],
      eligibilityTitle: 'Eligibility',
      eligibleDescriptions: [
        'Eligible Clients: New and existing.',
        'Eligible account to maintain a minimum of $5,000 in Eligible Funds daily during the previous settlement week (Monday to Friday).',
        'Fulfill 1 standard lot per $5,000 during the previous week (Monday to Friday) to receive daily cashback (0.05%).',
        'Cashback credited on Monday.',
      ],
      notesTitle: 'Notes',
      notesDescriptions: [
        'Withdrawal or internal transfer (transfer out) disqualifies accounts for that week (Monday to Sunday).',
        'Opted-out accounts cannot rejoin the promotion.',
      ],
      tnc: '*Terms and conditions apply. {link} to view',
      link: 'Click here',
      historyTitle: 'HISTORY',
      faqs: 'FAQS',
      agreeTnc: 'I have read and agree to the ',
      tncLink: 'Terms and Conditions',
      weeklyAchievement: 'Weekly Achievement',
      eligibleFunds: 'Eligible Funds (USD)',
      projectedDailyInterest: 'Projected Daily Interest* (USD)',
      days: { 0: 'Monday', 1: 'Tuesday', 2: 'Wednesday', 3: 'Thursday', 4: 'Friday' },
      totalWeeklyInterest: 'Total Weekly  Interest Amount (USD):',
      requirements: 'Requirements',
      weeklyTradedLots: 'Weekly Traded Lots:',
      noFundsOut: 'No Funds Out',
      optOut: 'Opt Out',
      joinWithOtherAcc: 'Join with Other Accounts',
      tableFooter:
        '* Clients are required to trade 1.0 lots per $5,000 daily eligible funds to receive the projected value.',
      update: 'Update',
      faq: {
        first: {
          title: 'How do I get 13% p.a. on my account balance?',
          answer1:
            '13% p.a. funds growth is based on a daily reward calculation of 0.05% per working day on eligible funds. The reward settles and will be credited to your participation account every Monday (for the previous week), resulting in a total of 13% annually.',
        },
        second: {
          title: 'What type of accounts can participate in 13% p.a. Funds Growth Promotion?',
          answer1:
            '13% p.a. funds growth is available only for Standard Account, Islamic Standard Account, Prime Account, Islamic Prime Account, ECN Account, MTS Standard Account, MTS Islamic-Standard Account, MTS Prime Account, MTS Islamic-Prime Account, MTS ECN Account.',
          answer2:
            '(Please note that the minimum deposit requirement for an ECN account is $10,000 USD or the equivalent.)',
        },
        third: {
          title: 'Can I participate with multiple accounts?',
          answer1:
            'Yes, you are allowed to participate with multiple accounts. However, each account must independently meet the participation requirements. The Eligible Fund and trading volume made on account other accounts cannot be combined to calculate your rewards.',
          answer2:
            'For example, if you participate with two accounts (account A and account B) <br> Account A meets the criteria <br> Account B does not meets the criteria,',
          answer3: 'You will receive the reward associated with account A only. ',
        },
        fourth: {
          title:
            'Why is there a difference between my equity and Eligible Fund for reward calculation?',
          answer1:
            '“Eligible Fund” is defined as your account equity subtracted by any credit bonuses in the participation account at 5:30 AM Server time every day. For instance, if your equity is USD 6,000 with a credit of USD 500, your rewards will be calculated from USD 5,500.',
          answer2:
            'Please note that if your equity is USD 5,000 with a credit of USD 500, no reward will be accrued as your Eligible Fund (USD 4,500) does not meet the minimum requirement (USD 5,000).',
        },
        fifth: {
          title:
            'Why is my actual reward payout lower than the projected value shown on the Client Portal?',
          answer1:
            'The actual reward payout is contingent on specific conditions: In order to receive the full reward, it is necessary to maintain eligible funds of at least USD 5,000 daily and execute a minimum of 1 standard lot per USD 5,000 in eligible funds during the settlement week. (For every additional USD 5,000, another standard lot is required.)',
          answer2: 'Failure to meet these criteria may result in a reduced or no reward payout.',
          answer3:
            '[Example A] If a client completes 1 standard lot of trading and maintains his/her eligible funds of USD 18,000 during the settlement week. He/she will receive a payout of USD 12.5 (USD 5,000 x 0.05% x 5 days ) for this settlement week.',
          answer4:
            '[Example B] If a client completes 2 standard lots of trading and maintains his/her eligible funds of USD 18,000 during the settlement week. He/she will receive a payout of USD 25 (USD 10,000 x 0.05% x 5 days) for this settlement week.',
          answer5:
            '[Example C] If a client completes 2 standard lots of trading and maintains his/her eligible funds of USD 8,000 during the settlement week. He/she will receive a payout of USD 20 (USD 8,000 x 0.05% x 5 days) for this settlement week.',
        },
        sixth: {
          title: "Why might that be the reason that I didn't get any payout this week?",
          answer1:
            'There are several reasons that may cause you not to receive the reward on the week:',
          answer2:
            'Failure to meet the criteria for getting the reward from the previous settlement week',
          answer3:
            'The reward from the last settlement week will only be credited to you when the last settlement cycle ends.',
          answer4:
            'Please contact our Customer Care Team via Live Chat, email: info@puprime.com, or phone +248 4373 105. if you encounter any technical issues on this offer. ',
          bullet1:
            'a. Your eligible funds fell below USD 5,000 on any day throughout the previous week.',
          bullet2:
            'b. You executed less than 1.0 standard lots per USD 5,000 in eligible funds during the previous week.',
          bullet3:
            'c. You made a withdrawal or internal fund transfer from participating accounts during the previous week.',
          bullet4: 'd. Your settlement week does not start from Monday.',
        },
        day: 'Day',
        funds: 'Eligible Funds',
        fundsLot: 'Eligible Funds with trading volume {lots} standard lot',
        payout: 'Payout received',
        total: 'Total Payout of the week',
      },
      history: {
        date: 'Settlement Date',
        tradingAcc: 'Trading Account',
        currency: 'Currency',
        weeklyInterest: 'Weekly Interest',
        invalid: 'Invalid Reason',
        invalidReason: {
          INSUFFICIENT_BALANCE: 'Insufficient Daily Eligible Funds',
          INSUFFICIENT_TRADE: 'Unmet Trading Requirements',
          WITHDRAWAL: 'Withdrawal',
          INTERNAL_TRANSFER: 'Internal Transfer',
        },
      },
      dialog: {
        selectAccPlaceholder: 'Please select account number',
        selectAccountContext:
          'You are required to select at least one (1) account to participate in this Promotion.',
        joinWithOther: 'Join with Other Accounts',
        optOutContext:
          'If you wish to opt-out, please click the button below. Please be informed that your accumulated interests available for next settlement will be forfeited once you perform this action.',
        successfully: 'Successfully!',
        successOptInText:
          'You have successfully opted-in to this promotion. The interest will be added to your account every Monday as long you maintain daily eligible funds and trade.',
        successOptOutText: 'You have successfully opted out of this promotion.',
      },
      banner: {
        title: 'Farm Your Wealth: Up To',
        desc: 'Every $5,000 unlocks daily cashback when you meet weekly trading goals.',
        desc2: 'Secure your wealth growth with consistent returns.',
      },
    },
    euro2024: {
      title: 'PU Prime Euro 2024',
      desc: 'Euro 2024 in Germany is a global football event and is also happening in PU Prime.\nA total of 5 spectacular games for users to participate & astonishing prize pool worth $2,480 to be won. \nThis campaign is only available in our PU Prime App. PU Prime App is available on App Store & Google Play.',
      eligibleDescriptions: [
        'Promotion Period from 7 June - 28 July 2024',
        'User must download the PU Prime App, new & existing users are welcome to participate.',
        'Credit Bonuses, Trade Loss Vouchers & Cash prizes will only be available for Standard account & Islamic Standard Account.',
        'If users has multiple Standard accounts, they can only select one Standard account to participate in this promotion.',
        'Promotion Areas - All countries except for prohibited jurisdictions as per the discretion of the company.',
        'Deposits and trades must be made during the promotion period to be considered valid.',
      ],
      tableTitle: 'Exclusive Sports Season Offer',
      tableDesc:
        'New & existing users can receive additional rewards by making deposits during the bonus period. Rewards payout will be done before 10th of the following month.',
      tableDate: '1 July - 31 August 2024',
      GD: 'Gross Deposit (USD)',
      tlVoucher: 'Trade Loss Voucher',
    },
    olympic2024: {
      title: 'PU Prime Olympic 2024',
      desc: 'Olympics Paris 2024 is a major international multi-sport event and it will be happening in PU Prime. A total of 4 sensational featured sports this summer in PU Prime App—Athletics, Basketball, Swimming, Volleyball with entertaining games & quests to conquer! An astounding prize pool of <span style="font-weight:bold;">$4,000</span> and more. This campaign is only available in our PU Prime App. PU Prime App is available on App Store & Google Play.',
      eligibleDescriptions: [
        'Promotion Period from 19 July - 25 Aug 2024',
        'User must download the PU Prime App, new & existing users are welcome to participate.',
        'Credit Bonuses, Trade Loss Vouchers & Cash prizes will only be available for Standard account & Islamic Standard Account.',
        'If users has multiple Standard accounts, they can only select one Standard account to participate in this promotion.',
        'Promotion Areas - All countries except for prohibited jurisdictions as per the discretion of the company.',
        'Deposits and trades must be made during the promotion period to be considered valid.',
      ],
    },
    firstDepositCashback: {
      banner: {
        title:
          '<span class="text_xs font_thin bg_yellow">Promotion</span><br>First Deposit<br>Cashback',
      },
      active: {
        title: 'Claim your first deposit cashback',
        desc: 'Please click the button below to opt in to the First Deposit Cashback promotion',
      },
      deactive: {
        title: 'Claim your first deposit cashback',
        desc: 'Please click the button below to opt out of the First Deposit Cashback promotion',
      },
      require: {
        title: 'Cashback Requirements',
        desc: 'Cashback value is based on amount of deposit bonus in account',
      },
      button: {
        active: 'Activate',
        fund: 'Fund your Account',
        signout: 'Sign Out',
        redeem: 'Redeem',
      },
      claimTitle: 'How to claim',
      claimDescriptions: [
        'Click on the "Activate" button in client portal to register for the promotion',
        'Make your first time deposit after activating the promotion',
        'Enjoy 20% of your deposited amount in credit bonus',
        'Trade and convert your credit bonus into cashback by meeting the required trading volume based on (deposit bonus awarded) multiplied by 35%',
        'Monitor your trading volume on the portal and redeem your cashback upon reaching the required volume',
      ],
      eligibilityTitle: 'ELIGIBILITY',
      eligibleDescriptions: [
        'The promotion must be activated before you make a deposit',
        'The bonus is ONLY available for Standard Accounts & Islamic Accounts',
        'Only new trades and first-time deposit made during the promotion period count towards the trading volume requirements for the First Deposit Cashback',
        'The total deposit bonus and cashback is capped at $10,000 per client',
      ],
      tnc: 'Term and condition apply. Click {link} to view',
      link: 'here',
      message: {
        congratulate: 'Congratulations!',
        congratulateContext: 'You have sucessfully activated the First Deposit Cashback.',
        signedOutPromo: 'By clicking the "Confirm" button, you\'ll be opted out of the promotion',
        deactivePromo: 'You have successfully opted out of the First Deposit Cashback',
        receiveFiveDaysLater: 'You will receive your cashback after 5 working days.',
        fiftyDepositBonus: "You're eligible for our next 50% Deposit Bonus.",
      },
      dialog: {
        selectAccountContext:
          'Please select the account which you would like to join the trading bonus activity.',
        tnc: 'Terms and Conditions',
        tncContext: {
          tnc1: '1. This promotion is valid for 60 days from the point of participation.',
          tnc2: '2. When the promotion expires, PU Prime will reset any remaining deposit bonus (es) redeemed under this promotion to zero without further notification to the clients therefore clients should make sure they have enough margin in their account to prevent the trades from getting liquidated.',
          tnc3: '3. The “First Deposit Cashback” is available ONLY for Standard Account and Islamic Standard Account.',
          tnc4: '4. To claim your first deposit bonus, please click the "Activate" button on this page to activate the promotion before making any deposits.',
          tnc5: '5. The credit bonus is calculated at 20% of the amount of the first deposit and the capped amount of the credit bonus is $10,000. This credit bonus can be used for withdrawal and trade.',
          tnc6: '6. The “First Deposit Cashback” applies to all clients who open real (meaning not demo) Account with PU Prime in accordance with the rules set herein and granted deposit bonuses may be used for trading purposes only. After making a deposit and receiving the credit bonus, you can start trading specific transaction volume, positions open more than 30 minutes within 60 days for withdrawal.',
          tnc7: '7. Internal transfers are not allowed between the trading accounts that have redeemed the “100% Cash Rebate, and 50% Deposit Bonus.”',
        },
        agreeTnc: 'I have read and agree to the Terms and Conditions',
      },
      field: {
        accountId: 'Account ID',
        tradedLost: 'Traded Lots',
        requireMent: 'Required Lots',
        cashBackValue: 'Cashback value',
      },
    },
    millionDollarCashPool: {
      banner: { bannerContext: 'PU Prime’s BIGGEST Cash Pool Rewards Ever!' },
      title: 'Register for Million Dollars Cash Pool Rewards',
      desc: 'Please click the button below to register in this promotion',
      registerNow: 'Register Now',
      claimTitle: 'HOW TO CLAIM',
      claimDescriptions: [
        'Register for the promotion before making a deposit.​',
        'Fund your trading account to meet the deposit requirements（≥ 200 USD).',
        'Cash vouchers will be directly transferred to the client’s balance after the end of the promotion.',
        'Enjoy trading and get the highest reward at the end of the promotion!',
      ],
      eligibilityTitle: 'ELIGIBILITY',
      eligibleDescription1:
        'The client must fill out the registration form to participate in this promotion. The registration form can be found on the {page}.',
      page: 'promotion landing page',
      eligibleDescription2:
        'This promotion is only available for Standard Accounts and Islamic Standard Accounts. If a client has multiple eligible trading accounts, only ONE eligible account can be selected to participate in this promotion.',
      eligibleDescription3:
        'This promotion starts from 12th Dec 2022 till 31st Jan 2023. Only new deposits made during this period will be counted towards the cashback calculation.',
      eligibleDescription4:
        'The Cash reward is based on the volume traded on these products: Forex, Gold, Silver, and Oil.',
      eligibleDescription5:
        'The client who has submitted a withdrawal during the promotion period will be deemed to have voluntarily withdrawn from the promotion.',
      fundAccount: 'Fund Your Account',
      field: {
        accumulateGross: 'Accumulate Gross Deposit USD (or equivalent currency)',
        cashbackVoucher: 'Cashback Voucher (USD)',
        requiredTradingVolumn: 'Required trading volume (Standard lots)',
      },
    },
    firstTimeDeposit: {
      banner: {
        bannerContext:
          '\n          <div class="title">Get <span class=\'bg_yellow\'>Instant Cash</span> on Your First Deposit</div>\n          <div class="desc">Get up to US$50 in cash when you make your first deposit with PU Prime!</div>\n        ',
      },
      active: {
        title: 'Activate YOUR FTD cashback',
        desc: 'Please click the button below to activate and participate in the activity.',
      },
      deactive: {
        title: 'Deactivate YOUR FTD cashback',
        desc: 'Please click the button below to deactivate and withdraw from the FTD cashback promotion.',
      },
      button: { active: 'Activate', deactive: 'Deactivate', fund: 'Fund Your Account' },
      claimTitle: 'HOW TO CLAIM',
      claimDescriptions: [
        'Activate the promotion before making a deposit.​',
        'Fund your trading account to meet the deposit requirements for the cashback you want（≥ 200 USD or Equivalent Currencies)​.',
        'Cashback voucher value will depend on the amount of your first deposit (see cashback table in the T&Cs).',
        'To activate your cashback voucher, trade ≥2 lots of FX, gold, silver, or oil.',
        'Your activated voucher can now be redeemed. This amount will be reflected in your account balance.',
      ],
      eligibilityTitle: 'ELIGIBILITY',
      eligibleDescription1:
        'Eligible account types: This offer is ONLY open to Standard accounts and Islamic Standard Accounts, both in MT4/MT5.',
      eligibleDescription2:
        'If a client has multiple Standard Accounts, they can only select one Standard Account to participate in this promotion with.',
      eligibleDescription3: 'Eligible countries: All except mainland China.',
      eligibleDescription4:
        'Eligible Clients: New and existing clients who have no deposit history，including clients that are under IBs/CPAs.',
      eligibleDescription4Desc: "*IB/CPA's personal trading accounts are not eligible",
      eligibleDescription5: 'The first deposit must be made during the promotion period.',
      eligibleDescription6:
        'Orders held for less than 5 minutes are not considered valid for the promotion.',
      tnc: '*Term and condition apply, {link} to view',
      link: 'click here',
      dialog: {
        selectAccountContext: 'Please select the account which you would like to join the activity',
        tnc: 'Terms and conditions',
        tncContext: {
          tnc1: '1. After you activate this promotion, you also have to deposit funds and trading 2 standard lots as required to receive the corresponding cashback.',
          tnc1desc:
            '*Only new deposits made during the promotion period are valid for the promotion. This excludes internal transfers, refer-a-friend bonuses, and cash adjustments.',
          tnc2: '2.\tOnly first deposit made during this period will be counted towards the calculation of the cashback.',
          tnc3: '3.\tEligible Clients (both new and existing clients), who meet the criteria set forth herein are able to receive cashback, equal to:',
          tnc4: '4.\tThe Eligible Products: Forex, Gold, Silver, oil.',
          tnc5: '5.\tThe effective trading volume: The newly opened and closed orders during the promotion period. Positions open for less than 5 minutes, locked positions, and hedged positions do not count as valid trades for this promotion.',
        },
        agreeTnc: 'I have read and agree to the Terms and Conditions.',
        grossDeposit: 'Gross Deposit USD',
        grossDepositDesc: 'or equivalent currency',
        cbVoucher: 'Cashback Voucher',
        tableDesc: '* Clients can redeem up to $50 (or equivalent) cash voucher.',
      },
      voucher: {
        title: 'FTD Cashback',
        accountID: 'Account ID',
        accumulateVolume: 'Accumulate Trading volume',
        standardLots: 'standard lots',
        desc: 'Trade ≥2 lots of FX, gold, silver, or oil to activate it',
        clickForRedeem: 'Click on the button below to send it to your account balance.',
        ftdLessThan200: 'Your initial deposit is less than the minimum required amount.',
      },
      message: {
        congratulate: 'Congratulations!',
        congratulateContext: 'You have successfully activated the FTD Cashback promotion.',
        signedOutPromo: 'By Clicking the Confirm Button, you will be signed out the promotion!',
        deactivePromo: 'You have successfully deactivate from the FTD Cashback Promotion.',
        successRedeem: 'You have successfully redeemed your FTD Cashback.',
      },
    },
    rugby: {
      title: 'PU Prime Rugby World Cup 2023',
      desc: 'Click the button below to participate in the activity',
      button: { join: 'Participate Now' },
      claimTitle: 'Description',
      eligibilityTitle: 'Eligibility',
      claimDescriptions: [
        'Rugby World Cup 2023 is a global event and is also happening in PU Prime. A total of 5 exciting mini games for users to participate. This campaign is only available in our PU Prime APP. PU Prime APP is available on App Store & Google Play.',
      ],
      eligibleDescriptions: [
        'Users must download the PU Prime APP to participate in this promotion.',
        'Credit Bonuses, Trade Loss Vouchers & Cash Prizes will ONLY be available for Standard account & Islamic Standard account.',
        'If users has multiple Standard accounts, they can only select one Standard account to participate in this promotion.',
        'Deposits and trades must be made during the promotion period to be considered valid.',
      ],
      tnc: '*Terms and conditions apply. {link} to view',
      link: 'click here',
      dialog: {
        back: 'Back',
        tnc: 'Terms and conditions',
        tncContext: {
          tnc1: '1. Clients need to successfully activate this promotion within their client portal first, and then deposit funds and trade as required to receive the corresponding gifts.',
          tnc2: '2.\tEffective trading volume: Standard lots of currency pairs, gold, silver, crude oil spot, and crude oil futures. Orders must be opened and closed during the promotion period. Positions open for less than 5 minutes, locked positions, and hedged positions do not count as valid trades for this promotion.',
          tnc3: '3.\tIf the gift cannot be purchased due to force majeure factors such as the product being off the shelves or out of stock, the customer can apply to receive cash equivalent to said gift’s market price. This price is based on the U.S. dollar price (or equivalent currency).',
          tnc4: '4.\tThis offer cannot be used in conjunction with any other offer. (RAF,100 trading bonus, 20% exclusive bonus, 50% deposit bonus, VN 20% deposit bonus).',
        },
      },
    },
    xmasSpinAndWin: {
      title: 'PU Prime Christmas Twinkling Lights',
      desc: 'Dive into the festive thrill with us this Christmas. Spin the wheel and let the twinkling lights guide you to exciting prizes, making your holiday celebrations even more magical.',
      buttonDesc: 'Click the button below to participate in the activity',
      button: { join: 'Participate Now' },
      claimTitle: 'How It Works?',
      eligibilityTitle: 'Eligibility',
      claimDescriptions: [
        'Complete the daily and weekly tasks on the PU Prime APP to accumulate points that you can spend to spin our advent wheel! Enter the leaderboard to win cash prizes up to US$3,000.',
      ],
      eligibleDescriptions: [
        'Users must download the PU Prime APP to participate in this promotion.',
        'Credit Bonuses, Trade Loss Vouchers & Cash Prizes will ONLY be available for Standard account & Islamic Standard account.',
        'Deposits and trades must be made during the promotion period to be considered valid.',
      ],
      tnc: '*Terms and conditions apply. {link} to view',
      link: 'click here',
      dialog: {
        back: 'Back',
        tnc: 'Terms and conditions',
        tncContext: {
          tnc1: '1. Clients need to download and log in to the PU Prime APP to participate in this promotion. Complete all daily and weekly tasks to receive the corresponding rewards.',
          tnc2: '2.\tPromotion period: 1 Dec 2023 - 31 Dec 2023. Voucher redemption period: 1 Dec 2023 - 31 Jan 2024.',
          tnc3: '3.\tEffective trading volume: Standard lots of currency pairs, gold, silver, crude oil spot, crude oil futures, and BTCUSD. Orders must be opened and closed during the promotion period. Positions open for less than 5 minutes, locked positions, and hedged positions do not count as valid trades for this promotion.',
          tnc4: '4.\tThis offer cannot be used in conjunction with any other offer of the same type.',
        },
      },
    },
    vdayPromo: {
      title: 'PU Prime Valentine’s Copy Trading Promotion',
      desc: 'Embrace the opportunity to win sensation cash prizes, from a prize pool totaling.',
      desc2:
        'Start matching your moves by becoming one of the Top 10 Copiers or Signal Providers in the exciting event. Do not miss this special promotion to win BIG, limited time offer on PU Prime APP.',
      buttonDesc: 'Click the button below to participate in the activity',
      buttonTitle: 'Participate Now',
      eligibleDescriptions: [
        'Promotion Period: 1 - 29 February 2024',
        'Eligible Participants: New & existing copy trading app account clients.',
        'Cash prizes will be awarded to the Top 10 Copiers who have copied the most number of Signal Providers during the promotion period.',
        'Cash prizes will be awarded to the Top 10 Signal Providers who have achieved the most number of Copiers during the promotion period.',
      ],
      tnc: '*Terms and conditions apply. {link} to view',
      link: 'Click here',
    },
    tradingContest: {
      stepDescTitle: '3 steps to win attractive cash prizes',
      prizes: 'Prizes',
      more: 'More',
      step: 'STEP {number}',
      step1Desc: 'Opt-in to the Promotion using your eligible account.',
      step2Desc: 'Deposit a minimum of USD${amount}.',
      step3Desc: 'Start trading to secure a spot on the leaderboard based on your {mechanics}.',
      deposit: 'Deposit Now',
      trade: 'Trade Now',
      optin: 'Opt-in Now',
      statusTitle: 'Status:',
      status: {
        noteligible: {
          title: 'Pending Deposit',
          tooltip:
            'Deposit at least <span class="font_bold">USD${amount}</span> to be eligible for the trading contest.',
        },
        pending: {
          title: 'Account Processing',
          tooltip:
            'The account application is currently being processed and will be ready for funding shortly.',
        },
      },
      contestAcc: 'Contest Account:',
      rank: 'Rank',
      profit: 'Profit',
      leaderboard: 'Leaderboard',
      leaderboardDesc1: 'The leaderboard is updated every hour.',
      leaderboardDesc2: 'Last updated on {dateTime}',
      column: {
        rank: 'Rank',
        name: 'Name',
        accountNo: 'Account Number',
        country: 'Country',
        profit: 'Profit ({usedSymbol})',
        usd: 'USD',
      },
      cancel: 'Cancel',
      submit: 'Submit',
      back: 'Back',
      notice: 'Notice',
      confirm: 'Confirm',
      withdrawDesc1:
        'Making withdrawals or performing internal fund transfers out from this trading account will result in disqualification from the {tradingContest}.',
      withdrawDesc2: 'This action is irreversible.',
      withdrawDesc3: 'Do you wish to proceed?',
      confirmation: 'Confirmation',
      oneStepAway: 'Just one more step',
      oneStepAwayDesc: 'You have successfully created a participating account.',
      oneStepAwayDesc2:
        'To be fully eligible for the trading contest, you will need to fund your participating account with at least <span class="highlight">${amount}</span>.',
      tnc: 'Terms and conditions',
      accountConfiguration: 'Account Configuration',
      accountOpening: 'Account Opening',
      agreeText: 'I have read and agreed to the{link}',
    },
    notification: {
      ndb: {
        inst1: 'Dear Valued Client,',
        inst2:
          'We kindly remind you that the No Deposit Bonus promotion will be finished on <span>{date} (GMT+3)</span> .',
        inst3:
          'According to the Terms and Conditions of the Promotion, please be advised that the bonus on your deposited account will be automatically removed once the promotion finish. For those no deposit or transaction record accounts, the bonus and balance will be automatically removed once the promotion finish as well.',
        inst4:
          'If you have any questions or require any assistance, please contact our support team via Live Chat, email: <span>{mail}</span>, or phone <span>{phone}</span>.',
        cantInerTransfer:
          'Since you are participanting our NDB campaign, we are unable to process your transfer request',
      },
      db: {
        inst1: 'Dear Valued Client,',
        inst2:
          'We kindly remind you that the 50% Deposit Bonus promotion will be finished on <span>{date} (GMT+3)</span> .',
        inst3:
          'According to the Terms and Conditions of the Promotion, please be advised that the bonus on your deposited account will be automatically removed once the promotion finish. For those no deposit or transaction record accounts, the bonus and balance will be automatically removed once the promotion finish as well.',
        inst4:
          'If you have any questions or require any assistance, please contact our support team via Live Chat, email: <span>{mail}</span>, or phone <span>{phone}</span>.',
      },
    },
    clearCreditDialog: {
      title: 'This action will remove all your credits earned from this campaign.',
      desc: '1) Please make sure you do not have any open trades.<br>2) You will not be able to reinstate the bonus credits after you click confirm.',
    },
  },
  downloads: {
    mt4: 'METATRADER 4',
    mt4Desc: 'Download MetaTrader 4 For PC, Mac, web browsers, smartphones, and tablets',
    windows: 'Windows',
    mac: 'Mac OS',
    ip: 'iPhone/iPad',
    android: 'Android/Tablet',
    web: 'WebTrader',
    mt5: 'METATRADER 5',
    mt5Desc: 'Download MetaTrader 5 For PC, Mac, web browsers, smartphones, and tablets',
    app: 'DOWNLOAD OUR APP',
    tabTitle: 'PU Prime APP',
    downloadTextTitle1: 'Download Our Trading APP',
    text1: 'Simple yet powerful trading tools',
    text2: 'Superfast execution speed',
    text3: 'Access to 800+ instruments',
    text4: 'Multiple funding channels',
    text5: 'Visual and analytical trading report',
    text6: 'Multi-account management',
    text7: 'Global market news on the go',
    text8: 'Access to professional 24/7 customer support',
    downloadTextTitle2: 'Download Now',
  },
  forgetPwReq: {
    tip: 'Enter your account’s email address to reset your password.',
    formValidation: { emailReq: 'Login email is required' },
    succ: 'We have just sent you an email with instructions to reset your password.<br>Please ensure you check your email’s spam folder.',
  },
  loginClient: {
    header: 'Client Portal',
    slogan: 'more than trading',
    failedMasseage: 'Try again',
    existingClientPortalAccess:
      'We’ve noticed that you already have access to our Client Portal. Please log in using your email to proceed.',
    footer:
      'Risk Warning: Contracts for Difference (CFDs) trading carries a high level of risk to your capital and can result in losses, you should only trade with money you can afford to lose. CFDs trading may not be suitable for all investors, please ensure that you fully understand the risks involved and take appropriate measures to manage it.<br><br><br>\n      {platformEntityName} is authorised and regulated by the Financial Services Authority of Seychelles with License No. SD050.<br>\n      PU Prime LLC is authorised and regulated by the Financial Services Authority of St. Vincent and the Grenadines with License No. 271 LLC 2020.<br>\n      The entities above are duly authorized to operate under the PU Prime brand and trademark.<br>',
    formValidation: { emailReq: 'Email address is required' },
    invailidRafLink: 'The referral link used is invalid. Sign up for a new account below.',
    emailIsAlreadyRegistered: 'Email Is Already Registered',
    forgotPasswordTip:
      "Forgot your password? Click 'Reset Password' to initiate the reset process.",
  },
  myProfile: {
    changeDetails:
      'If you wish to update your existing profile details, do not hesitate to contact {mailTo}',
  },
  openAdditAcc: {
    chooseTradePlat: 'CHOOSE A TRADING PLATFORM',
    chooseAccType: 'CHOOSE AN ACCOUNT TYPE',
    chooseCurrency: 'CHOOSE AN ACCOUNT CURRENCY',
    chooseLeverage: 'Choose Leverage',
    chooseBalance: 'Choose Account Balance',
    notes: 'ADDITIONAL NOTES',
    currError: 'You must select an account currency',
    platErr: 'You must select a trading platform',
    accTypeErr: 'You must select an account type',
    leverageErr: 'You must select a leverage',
    balanceErr: 'You must select a balance',
    succ: '<p>Your additional live account is being set up and you will receive an email with your login details shortly.You can view the current status of your application from the home page.</p>',
    byTicking: 'By ticking this box, I agree to all the below Terms and Conditions. {tnc}',
    byTickingCopyTrading:
      'By ticking this box, I agree to Copy Trading Terms and Conditions. {tnc}',
    tnc: 'View all Terms and Conditions',
    pamm: 'PAMM Accounts are only for investors with an existing Money Manager arrangement. PAMM Accounts cannot be traded by anyone except the Money Manager.',
    pammConfirm: 'Please confirm whether to continue selecting this account type.',
    disclaimer:
      '<li>I acknowledge that I have read, understood and accept the <a href="https://{regulatorUrl}/company/legal/legal-document/clientserviceagreement/">{platform} Client Service Agreement</a> provided online as part of this application.</li>\n    <li>I also confirm that I have read, understood and agree to be bound by the <a href="https://{regulatorUrl}/company/legal/legal-document/websitetermsandconditions/">{platform} Website Terms and Conditions</a>, <a href="https://{regulatorUrl}/company/legal/legal-document/moneylaunderingpreventionpolicy/">Money Laundering Prevention Policy</a>, <a href="https://{regulatorUrl}/company/legal/legal-document/riskdisclosure/">Risk Disclosure</a> and <a href="https://{regulatorUrl}/company/legal/legal-document/privacypolicy/">Privacy Policy</a>.</li>\n    <li>I understand that {platform} will not provide me with any investment advice on transactions.</li>\n    <li>I understand that personal information submitted as part of this application will be used to verify my identity with a third-party authentication service.</li>\n    <li>I confirm that the information provided by me and inserted into this form is correct and that I acknowledge that I shall be obliged to inform {platform} immediately in the case of any changes to this information.</li>\n    <li>I confirm that I have acted in my own name as specified in this application and not on the behalf of a third party in respect to all matters related to this client relationship. Accordingly, all funds to be deposited and traded with on the account with {platform} are my own funds.</li>\n    <li>I confirm that the intended deposit amount chosen by me takes into consideration my total financial circumstances and is considered by me to be responsible under such circumstances. I understand the inherent risks of trading CFDs and that due to the risky nature of the product I may lose more than my deposit.</li>',
    minDeposit: 'Minimum deposit before trading is enabled: ',
    infoHover: 'Eligible for the following promotions: ',
    importantNote: 'Important Note: ',
    islamicTnc:
      'Please be advised that Islamic Trading Accounts benefit from holding positions for 14-day period during which no administration fees are applied. After this period, standard administration fees will be applied for positions holding for more than 14 day.',
    nonIslamicTnc:
      'Islamic accounts allow Muslim traders to follow Sharia law by offering swap-free trading with no overnight interest fees. Instead, an administration fee on each position would be debited from the account. For more information, please click {link}.',
    here: 'here',
    selectMt4Tips: {
      title: 'Are you sure you want to select MetaTrade 4?',
      tipText: 'MetaTrade 4 offers fewer built-in features and limited product diversity.',
      switchBtn: 'Switch to MetaTrade 5',
      continueBtn: 'Continue with MetaTrade 4'
    },
  },
  openDemoAdditAcc: {
    title: 'Create Demo Account',
    header: 'demo Account Configuration',
    button: 'open demo account',
    liveButton: 'open live account',
    succ: 'Your additional demo account has been set up and you will receive an email with your login details shortly.',
  },
  resetPassword: {
    header: 'Reset Password',
    succ: 'Your password has been updated. You may now use your new password to access your MT4/MT5 trading account.',
    succ2: 'Your password has been updated.',
    failed: 'Token is expired',
    emailNotFound: 'Unfortunately your email address was not recognized',
    inputLoginEmail: 'Input Your Login Email',
  },
  fileUploader: {
    supportedFormat: 'Supported file types: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Supported file types: {files}',
    maxSize: 'Maximum upload file size: {maxSize}MB',
    maxFileNum: 'Maximum file number: {maxFileNum}',
    maxFileSize: 'The uploaded file size cannot exceed {maxSize}MB',
    sureToDelete: {
      title: 'Are you sure',
      content: 'Are you sure you want to delete the uploaded file？',
    },
  },
  transcationHistory: {
    deposit: 'DEPOSITS',
    withdraw: 'WITHDRAWALS',
    desc: {
      incomplete:
        'If you terminate or fail to complete the deposit process, the transaction status will show as “incomplete”. You will be required to create a new deposit as this transaction cannot be resumed.',
      success: 'Your transaction was successful. Your funds should appear in your account shortly',
      proc: 'Your transaction is being processed. Please contact {mailTo}',
      failed: 'Your transaction has failed. Please contact {mailTo} for more information',
      rejected: 'Your transaction has been rejected. Please contact {mailTo} for more information',
    },
    withdrawDesc: {
      sub: 'Your transaction has been submitted. Please wait to complete.',
      suc: 'Your transaction was successful. Your funds should appear in your account shortly',
      proc: 'Your transaction is being processed. Please contact {mailTo}',
      incomp:
        'Your Transaction requires additional steps to finalise. Please contact {mailTo} for more information',
      cancel: 'Your transaction has been cancelled. Please contact {mailTo} for more information',
      fail: 'Your transaction has failed. Please contact {mailTo} for more information',
      rej: 'Your transaction has been rejected. Please contact {mailTo} for more information',
      partialSucc:
        'Your transaction has been partially refunded. Please contact {mailTo} for more information',
    },
    cancelWithdraw: 'Would you like to Cancel the Withdrawal Request?',
    cancelError:
      'Your withdrawal is being processed at the moment. Therefore, we cannot accept your cancellation request. For more information, you may reach us using the live chat feature.',
    dialog: {
      appliedAmount: 'Applied Amount',
      applicationTime: 'Application Time',
      processedTime: 'Processed Time',
      accountCurrency: 'Account Currency',
      creditDeducted: 'Credit Deducted',
      processedNote: 'Processed note',
      bankName: 'Bank Name',
      bankAccountNumber: 'Bank Account Number',
      abaSortCode: 'ABA/Sort Code',
      swiftCode: 'Swift Code',
      bankStatement: 'Bank Statement',
      bankAddress: 'Bank Address',
      accountName: 'Account Name',
      accountNumber: 'Account Number',
      cardholderName: 'Cardholder Name',
      cardNumber: 'Card Number',
      cardExpiryDate: 'Card Expiry Date',
      walletAddress: 'Wallet Address',
      ewalletEmailAddress: 'E-wallet’s email address',
    },
  },
  transferFunds: {
    default: {
      equityStop:
        'Unfortunately your transfer cannot be processed because it will result in your account’s available margin falling below the stop out level of 100%',
      equityMargin:
        'Unfortunately we are currently unable to process your transfer because your account is fully hedged.',
      equityContinue:
        'We have detected you currently have open positions, you may experience stop out after submitting your transfer application. Please confirm if you wish to proceed.',
      blackList:
        'This account has been restricted from making a transfer between accounts, please contact {supportEmail}',
    },
    pleaseNote: 'Please note:',
    suc: 'Your funds have been transferred successfully',
    warn1:
      'You are responsible for maintaining an account balance that will not trigger a margin call. If you have any current open positions, please ensure you maintain the required margin in your account. Funds transferred to accounts held in the same currency are processed instantly.',
    warn2: 'If transferring funds between accounts held in different currencies, email {mailTo}.',
    warn3: 'Please note that funds cannot be transferred between read-only accounts.',
  },
  transferHistory: {
    payStatus: { processing: 'Processing', paid: 'Paid', unpaid: 'Unpaid', rejected: 'Rejected' },
  },
  identityProof: {
    home: {
      header: 'ACCOUNT APPLICATION INCOMPLETE',
      content:
        'Hi {name}, your account application is almost complete, however you still need to submit additional documentation to finalise your account.',
      poaRequired: {
        header: 'Verification required',
        content:
          'In order to ensure the security of your account, we will require you to successfully complete address verification before withdrawals are enabled.\r\n\r\nPlease proceed to complete the necessary verification below.',
      },
    },
    shufti: {
      content:
        'Please ensure the details provided match your ID documentation, and are in the same language.',
      countryOfDocument: 'Country as shown on document',
    },
    popup: {
      header:
        'Hi {name}, before your live account can be finalised you are required to upload the following document for verification.',
      addressProofSpan:
        '<strong>Confirm Address</strong><br />\n      Submit one of the documents below<br /><br />\n      - Copy of a recent Utility Bill (no older than 6 months)<br />\n      - Copy of a recent Bank Statement (no older than 6 months)<br />\n      <br />\n      IMPORTANT! Name on document MUST clearly show your full name and current address.',
      idProofSpan:
        "<strong>Confirm ID</strong><br />\n      Choose one of the options below<br /><br />\n      - Copy of Valid Passport<br />\n      - Copy of Valid Driver's License<br />\n      - Copy of National ID Card(Front and back)<br />\n      <br />\n      IMPORTANT! Name on document MUST clearly show your full name.",
    },
    reason: 'Your documentation was unable to be verified,<br> Reason: {reason}. Please try again.',
  },
  header: { secPortal: 'Secure<br />Client Area' },
  menu: {
    home: 'HOME',
    accManagement: 'ACCOUNTS',
    liveAcc: 'LIVE ACCOUNTS',
    openAdditAcc: 'ADDITIONAL ACCOUNTS',
    homeDemo: 'DEMO ACCOUNTS',
    depositFund: 'DEPOSIT FUNDS',
    withdrawFunds: 'WITHDRAW FUNDS',
    transferBetweenAccs: 'TRANSFER FUNDS',
    payDetails: 'PAYMENT DETAILS',
    funds: 'FUNDS',
    transactionHis: 'TRANSACTION HISTORY',
    tranHis: 'TRANSFERS',
    download: 'DOWNLOADS',
    profile: 'PROFILE',
    myProfile: 'MY PROFILE',
    cpPassword: 'CHANGE PASSWORD',
    authentication: 'AUTHENTICATION',
    contactUs: 'CONTACT US',
    register: 'CONTINUE APPLICATION',
    tradingTools: 'PRO TRADER TOOLS',
    MARKET_BUZZ: 'MARKET BUZZ',
    ECON_CALENDAR: 'ECONOMIC CALENDAR',
    FEAT_FX: 'FEATURED IDEAS',
    FX_IDEAS: 'ANALYST VIEWS',
    autochartist: 'AUTOCHARTIST',
    promotion: 'PROMOTION',
    noDeposit: 'NO DEPOSIT BONUS',
    referFriends: 'REFER A FRIEND',
    depositBonus: '50% DEPOSIT BONUS',
    deposit20Bonus: '20% DEPOSIT BONUS',
    tradingBonus: '100% CASH REBATE',
    cryptoCashback: 'CRYPTO CASHBACK',
    xmasMakeAWish: 'XMAS MAKE A WISH',
    millionDollarCashPool: '$20.23 MILLION REASONS',
    firstTimeDeposit: 'FTD CASHBACK',
    firstDepositCashback: 'FIRST DEPOSIT CASHBACK',
    propTrade: 'PU XTRADER',
    propTradeCreateAccount: 'Create Account',
    propTradeAchievement: 'Achievements',
    propTradeDashboard: 'Dashboard',
    rugby: 'Rugby World Cup',
    xmasSpinAndWin: 'Xmas Spin & Win',
    nfp: 'NFP App Promo',
    vdayPromo: "Valentine's App Promo",
    securityManagement: 'Password & Security',
    euro2024: 'Euro 2024',
    olympic2024: 'Olympic Paris 2024',
    fgp: '13% p.a. Funds Growth',
  },
  security: {
    resend: 'Resend',
    modify: 'Modify',
    confirmPwd: 'Confirm Password',
    changeDesc:
      'To ensure the security of the funds in your account, withdrawals will not be supported for T+ {numDays} days after the changing of your {method}.',
    changeAuthenticatorTitle: 'Change Security Authenticator',
    enable: 'Enable',
    verify: 'Verify',
    emailTitle: 'Enter new email address',
    changeEmailSuccess: 'Email address updated',
    changeEmailSuccessDesc:
      'Your email has been updated. <br/> You may now use new email address to login.',
    changePasswordSuccess: 'Password updated',
    changePasswordSuccessDesc:
      'Your password has been updated. <br/> You may now use new password to login.',
    enableSecuritySuccess: 'Security Authenticator enabled',
    enableSecuritySuccessDesc: 'Your security authenticator APP has been enabled.',
    updateSecuritySuccess: 'Security Authenticator updated ',
    updateSecuritySuccessDesc: 'Your security authenticator APP has been updated.',
    enableNow: 'Enable Now',
    verifyPostEmailNote: 'Code sent to {email}',
    verifyPreEmailNote: 'Code will be sent to {email}',
    securityAuthentication: 'Security Authentication',
    contactSupport:
      'Unable to access authentication method? <br/> Please contact {contact_email} or Livechat.',
    loginLocationChange: 'Login Location Change Email Notification',
    authenticationMethod: 'Authentication Method',
    securityManagement: 'Security Management',
    isVerified: 'Verified',
    isNotVerified: 'Unverified',
    securityAuthenticatorApp: 'Security Authenticator App',
    lastUpdate: 'Last update',
    password: 'Password',
    enableLoginAuthentication: 'Enable Login Authentication',
    scanWithApp: 'Scan with Authenticator APP',
    authenticatorTooltip: 'Authenticator APP verification code refreshes every 30 seconds',
    addAuthenticator: 'Add Authenticator Device',
    editAuthenticator: 'Edit Authenticator Device',
    setupKey: 'Setup key: ',
    verificationTitle: 'Verification Required',
    verificationDesc: 'Please enable Security Authenticator APP before proceed.',
    preEmailCode: 'Code will be sent to your new email address',
    scanAppTooltip:
      'You may download Google Authenticator or Microsoft Authenticator from your phone application store. Then, use in-app camera to scan the QR code provided.',
    scanAuthenticatorAppTips: {
      tips1:
        'You can download Google Authenticator or Microsoft Authenticator from the mobile app store',
      tips2:
        'Alternatively, you can also search for Google Authenticator in the WeChat Mini Program and select the first result.',
      tips3: 'Then, use the in-app camera to scan the provided QR code',
    },
    userGuide: 'User Guide',
    tnc: 'By linking an authenticator app, you confirm you have read and accepted the{link}.',
    disclaimer: 'disclaimer',
    updateSuccess: 'Update Successfully',
    verifiedSuccess: 'Verified Successfully',
    passwordReq: 'Password Requirements: ',
    passwordCharacters: '8-16 characters',
    passwordMix: 'A mix of letters (a-z and A-Z), numbers (0-9)',
    passwordSpecial: 'Special characters like !@#$%^&*.()',
    changeEmail: 'Change Email Address',
    verificationRequired: 'Verification Code is required.',
    next: 'Next',
    preferredLang: {
      title: 'Preferred Language',
      currentLang: 'Current Selection: {lang}',
      default: 'by default',
      dialogTitle: 'Modify Preferred Language',
      dialogDesc:
        'Please choose your preferred language from the menu below for the communication materials your receive from PU Prime.',
      language: 'Language',
      resultTitle: 'Email Preferred Language updated',
      resultDesc: 'Your preferred language have been updated successfully to - {lang}',
    },
    bindErrorTip: 'Please refresh the page and obtain the latest secert key to rebind the Two-Factor Authenticator.',
  },
  contact: {
    click: 'Click',
    assist: ' for assistance now',
    desc: "{platform}'s professional multilingual customer support team is ready to assist you and help to provide you an incomparable trading experience.",
    followUs: 'FOLLOW US',
    tip: 'For clients who onboarded via {email}',
    liveChat: 'Live Chat',
    chatNow: "Let's Chat now",
    globalOffice: 'Global Office',
  },
  depositResultDialog: { msg: 'Have you successfully completed your deposit' },
  dialog: {
    identityProof: {
      success:
        'Thank you. We have successfully received your documents and are currently processing them for verification.',
    },
  },
  sessionDialog: {
    title: 'Your session is about to expire!',
    content: 'You will be logged out in 60 seconds. Do you want to stay signed in?',
    keepIn: 'Yes, Keep me signed in',
    signOut: 'No, Sign me out',
  },
  notification: {
    pendingIdentityProof: {
      header: 'ACCOUNT APPLICATION PROCESSING',
      id: 'ID is',
      poa: 'Address is',
      both: 'ID and Proof of address are',
      content:
        'Your Proof of {doc} currently being processed and will be finalized shortly. To fast track your live account processing, you can fund your account now and receive priority opening.',
    },
  },
  verificationStatus: {
    title: { default: 'You’re Almost Done', LV3: 'Welcome' },
    dueToAML:
      'You’re ready to start trading soon! Help us safeguard your account by verifying your personal information.',
    accOpeningVerification: {
      title: 'Create Live Account',
      subTitle: 'More info on our account types {link}.',
      here: 'here',
      content: 'Create a Live Account to start funding your account.',
    },
    identityVerification: {
      title: 'Identity Verification',
      content: 'Submit and verify your proof of identity to start trading.',
    },
    poaVerification: {
      title: 'Address Verification',
      content: 'POA needs to be verified before you can make a withdrawal.',
    },
    initInfo: {
      title: 'Additional Info Required',
      content:
        'Fill in your financial profile information to begin the next step in the verification of your account',
    },
    submittedInfo: {
      title: 'Your Submission is Under Review.',
      content:
        'Your documents for wire transfer are still under review. You can still make a withdrawal via another channel.',
    },
    pendingInfo: {
      title: 'Additional Info Required',
      content:
        'Some of your identifying documents might be missing or incorrect. Please re-upload them to proceed.',
    },
    kyc: {
      depositNow: 'Deposit Now',
      withdrawNow: 'Withdraw Now',
      createNow: 'Create Now',
      start: 'Start',
      upload: 'Upload',
      complete: 'Completed',
      pending: 'Pending',
      rejected: 'Rejected',
      resubmit: 'Resubmit',
      downloadMetaTrader: 'Download MetaTrader',
      otherOption: 'Withdraw Via Other Channel',
    },
    resubmitNote: 'Your documents might be missing or incorrect. Please resubmit.',
    rejectedNote: 'Questions?  Please contact us via {liveChat} .',
    liveChat: 'Live Chat',
    uploadTemplate: {
      idGuideTitle: 'ID Document Guidelines',
      uploadGuideTitle: 'Upload Guidelines',
      desc1: 'Government-issued',
      desc2: 'Original, full-size, unedited documents',
      desc3: 'Place documents against a single-coloured background',
      desc4: 'Readable, well-lit, and clear colour image',
      desc5: 'No black and white images',
      desc6: 'No edited or expired documents',
      bottomDesc: 'The uploaded file can be jpg, png, or pdf, and the file size cannot exceed 5MB',
    },
  },
  register: {
    progressStatus: {
      title: 'Verification progress',
      estimatedTimeTitle: 'estimated time',
      min: 'minutes',
    },
    tabs: {
      personalDetails: 'Personal Details',
      moreAboutYou: 'More About You',
      accountConfig: 'Account Configuration',
      confirmYourId: 'Confirm Your ID',
      fundYourAcc: 'Fund your Account',
      personalInformation: 'Personal Information',
      createLiveAccount: 'Create Live Account',
      financialInfo: 'Financial Info',
      iDProof: 'ID Proof',
      addressProof: 'Address Proof',
    },
    btn: {
      next: 'NEXT',
      back: 'BACK',
      fundnow: 'FUND NOW',
      upload: 'Upload',
      done: 'DONE',
      bTCP: 'Back to Client Portal',
      proceedToUpload: 'Proceed to Upload',
      completeID: 'Complete ID Verification',
      completePOA: 'Complete POA Verification',
      depositViaOther: 'Deposit Via Other Channel',
    },
    authority: {
      dialog1: 'You must have a live account to access this section of the client portal.',
      dialog2:
        'Open a live account now to unlock full access to your client portal, which includes a range of trading tools, promotions and much more.',
      openLiveAcc: 'open live account',
    },
    demo: {
      congrad: 'Congratulations!',
      title: 'Your 60 Day DEMO MT{platform} account is now active',
      title2: 'Your DEMO Account Details',
      li: { login: 'Login', srv: 'Server', expDate: 'Expiry Date' },
      emailSent: 'Login details have also been sent to your registered email.',
      dl: 'Download your MT{platform} Trading Platform',
      improveQ: 'Do you want to improve your trading？',
      improveWay:
        'Simply, open a live account in just minutes to unlock a range of exclusive trading tools and promotions.',
      proTrade: 'Professional marketing reports',
      customerService: '24/7 One-on-one customer service',
      competitivePromotionals: 'Competitive promotionals',
      secure: '3D secure transactions',
      openLiveAcc: 'open live account',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Personal Details',
        userTitle: 'Title',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'E-mail',
        dob: 'Date Of Birth',
        idType: 'Identification Type',
        referral: 'Have you been referred by anyone？  (optional)',
        middleName: 'Middle Name',
        nationality: 'Nationality',
        phoneNum: 'Phone Number',
        date: 'Day',
        month: 'Month',
        year: 'Year',
        placeOfBirth: 'Place Of Birth',
        idNum: 'Identification Number',
        passportNum: 'Passport Number',
        driverLicenseNum: 'Driver License Number',
        titleDropdown: { mr: 'Mr', mrs: 'Mrs', ms: 'Ms', miss: 'Miss', dr: 'Dr', prof: 'Prof' },
        idTypeDropdown: {
          passport: 'Passport',
          idCard: 'National ID Card',
          driverLicense: 'Driver License',
        },
      },
      page2: {
        pageTitle: 'MAIN RESIDENTIAL ADDRESS',
        mainResidency: 'Country of main residency',
        streetNumber: 'Street Number',
        streetName: 'Street Name',
        address: 'Address',
        provinceOrState: 'Province / State',
        cityOrSuburb: 'City / Suburb',
        postcode: 'Postcode',
        usQuestion:
          'Are you a US Citizen/Resident for tax purposes or do you have any US links/ties(i.e. Bank account, etc.)',
      },
    },
    moreAboutYou: {
      pageTitle1: 'EMPLOYMENT AND FINANCIAL DETAILS',
      pageTitle2: 'Trading Knowledge and Experience',
      complianceExplain:
        'The following questions are collected for compliance with AML/CTF requirements.',
    },
    accountConfig: {
      pageTitle: 'Account Configuration',
      chooseTradingPlatform: 'Choose Trading Platform',
      chooseAccType: 'Choose Account Type',
      chooseAccCurrency: 'Choose Account Currency',
    },
    confirmYourId: {
      pageTitle: 'Confirm Your ID',
      description:
        'As a regulated forex broker, we are required to verify your identity. Please upload one of the following documents for your Proof of ID and Proof of Address verification. Alternatively, you may email your verification documents to {mailTo}',
      poidTitle: 'Proof of Identity',
      porTitle: 'Proof of Residency',
      uploadDescription: 'Choose a document type and upload accordingly',
      uploadTip:
        'Please Upload Both Front and Back of Your Photo ID. (Click the blue tick to continue uploading)',
      note: 'IMPORTANT: Name on document MUST clearly show your full name.',
      pending: { upload: 'Pending Upload', verification: 'Pending Verification' },
      poidTypes: {
        photoId: 'Front and Back of Photo ID',
        passport: 'Passport',
        drivingLicence: 'Driving Licence',
      },
      porTypes:
        '\n        <li>Utility Bill</li>\n        <li>Bank Statement</li>\n        <li>Residence Certificate</li>\n      ',
    },
    finishPage: {
      title: '{individualUserName}, thank you for applying for a {platformName} live account!',
      contentDefault:
        "We have received your application and set up your live account <b>{account}</b>. <br />\n      Shortly, you will receive an email at <b>{email}</b> containing your login details and a link to view your account's status. <br />",
      contentWithoutId3WithAccount:
        'Your live trading account is currently being processed and you will receive an email containing further information shortly.<br />\n      To fast track your live account processing, you can fund your account now and receive priority opening.<br />',
      contentWithoutId3WithoutAccount:
        'Your live trading account is currently being processed and you will receive an email containing further information shortly.<br />',
    },
    accOpeningVerification: {
      accOpenVer: 'Account Opening Verification',
      personalInformation: {
        narrative: 'Your personal information is required to open your Live Account',
        firstName: 'First Name',
        middleName: 'Middle Name',
        lastName: 'Last Name',
        gender: 'Gender',
        genderList: { male: 'Male', female: 'Female', other: 'Other' },
        dob: 'Date Of Birth',
        day: 'Day',
        month: 'Month',
        year: 'Year',
        mobile: 'Mobile Number',
        countryName: 'Country of Residence',
        nationality: 'Nationality',
        email: 'E-mail',
      },
      createLiveAccount: {
        narrative:
          'Complete a few steps to open an account and start funding. More info on our account types <a href="{link}" target="_blank" alt>here</a>.',
        choose: 'Choose {dynamic}',
        tradingPlatform: 'Trading Platform',
        accountType: 'Account Type',
        accountCurrency: 'Account Currency',
        islamicTooltip:
          '{platform} may require you to provide supporting document(s) to verify that you are of the Islamic faith. {platform} reserves the right to amend your account configuration if verification fails.',
        successInfo: {
          title: 'Trading Account Created',
          content:
            'Congratulations! You have successfully completed account opening. <br /> To start trading, make a deposit now and complete your ID verification.',
        },
        successInfo2: {
          title: 'Trading Account Created',
          content:
            'Fill in your financial profile information to begin the next step in the verification of your account',
          start: 'Start',
        },
        generalInfo: {
          title: 'Your Submission is Under Review.',
          content:
            'Thank you for opening an account with {platform}. You can begin funding your account once your submission is approved.',
        },
        rejectedInfo: {
          title: 'Rejected',
          content:
            'We regret to inform you that your request to open a {platform} account has been rejected.',
        },
        tradingPlatformType: { mt4: 'MT4', mt5: 'MT5', copyTrading: 'Copy Trading' },
        mamNumber: 'MAM Number',
        mamNumberPlaceholder: 'Enter MAM Number (optional)',
      },
    },
    identityVerification: {
      title: 'Identity Verification',
      subTitle: 'Identity Information',
      narrative: 'Fill in the details',
      firstName: 'First Name',
      middleName: 'Middle Name',
      lastName: 'Last Name',
      nationality: 'Nationality',
      idType: 'ID Type',
      idSerialNumber: 'ID Serial Number',
      passportNum: 'Passport Number',
      driverLicenseNum: 'Driver License Number',
      ID: 'ID',
      upload: {
        title: 'Upload Document',
        desc: 'Make sure the document shows your photo, full name, date of birth, and date of issue.',
        guideline: 'Upload Guidelines',
        positive: 'Upload Positive',
        signaturePage: 'Upload Signature page',
      },
      idTypeDropdown: {
        passport: 'Passport',
        idCard: 'National ID Card',
        driverLicense: 'Driver License',
        other: 'Other',
      },
      additionalInfo: {
        title: 'Additional Info Required',
        content: 'Verify your identity to start trading',
      },
      successInfo: {
        title: 'ID Verification Complete',
        content:
          'Congratulations! Your ID has been verified. You can now start trading on MT4/MT5 or the {platform} app if you have already made a deposit.',
      },
      generalInfo: {
        title: 'Your Submission is Under Review.',
        content:
          'Thank you for opening an account with {platform}. You can begin trading once your identity information is verified.',
      },
      rejectedInfo: {
        title: 'Rejected',
        content:
          'We regret to inform you that your request to open a {platform} account has been rejected.',
      },
      identifyingRequiredInfo: {
        title: 'Additional Info Required',
        content:
          'Some of your identifying documents might be missing or incorrect. Please re-upload them to proceed.',
      },
      streetNumber: 'Street number',
      streetName: 'Street name',
    },
    poaVerification: {
      proofOfAddressVer: 'Proof of Address Verification',
      subTitle: 'Address Information',
      narrative: 'Fill in the details',
      dueToAML:
        'Due to AML requirements, your address must be verified before you can make a withdrawal.',
      nationality: 'Nationality',
      country: 'Country Of Residence',
      address: 'Address',
      postalCode: 'Postal Code',
      citySuburb: 'City/Suburb',
      city: 'City',
      provinceState: 'Province/State',
      upload: {
        title: 'POA Certificate',
        inst1: 'Utility bill (electricity, water, gas)',
        inst2: 'Bank or credit card statements',
        desc: 'POA information for withdrawals must be issued within the past {months} months',
        guideline: 'Upload Guidelines',
        uploadFilesPhoto: 'Upload Files/Photo',
      },
      additionalInfo: {
        title: 'Additional Info Required',
        content: 'POA information needs to be verified before you can make a withdrawal.',
      },
      successInfo: {
        title: 'Proof of Address Verified',
        content: 'Congratulations! Your POA has been verified. You can now make withdrawals.',
      },
      generalInfo: {
        title: 'Your Submission is Under Review.',
        content:
          'Thank you for trading with {platform}. You can begin the withdrawal process once your submission is approved.',
      },
      identifyingRequiredInfo: {
        title: 'Additional Info Required',
        content:
          'Some of your identifying documents might be missing or incorrect. Please re-upload them to proceed.',
      },
    },
    financialVerification: {
      financialInfo: {
        ver: 'Your Financial Information',
        subTitle: 'Employment and Financial Details',
        subTitle2: 'Trading Knowledge and Experience',
        employmentStatus: 'Employment Status',
        USDRemoteTitle: '{title} (USD)',
        perWeekTitle: '{title} Per Week',
        estimatedAnnualIncome: 'Estimated Annual Income',
        estimatedSavingsAndInvestments: 'Estimated Savings and Investments',
        estimatedIntendedDeposit: 'Estimated Intended Deposit',
        sourceOfFunds: 'Source of Funds',
        numberOfTrades: 'Number of Trades',
        tradingAmount: 'Trading Amount',
      },
      iDProof: {
        ver: 'Upload Proof of Identity',
        subTitle: 'Identity Information',
        narrative: 'Fill in the details',
      },
      addressProof: {
        ver: 'Upload Proof of Address (POA)',
        subTitle: 'Address Information',
        narrative: 'Fill in the details',
        upload: {
          title: 'Accepted Documents for Proof of Address',
          inst1: 'Utility bill (electricity, water, gas)',
          inst2: 'Bank or credit card statements',
          desc: 'POA information for bank authentication must be issued within the past {months} months',
          guideline: 'Upload Guidelines',
          uploadFilesPhoto: 'Upload Files/Photo',
        },
      },
      successInfo: {
        title: 'Bank Authentication Complete',
        content: 'Congratulations! You have successfully verified your banking details.',
      },
      generalInfo: {
        title: 'Your Submission is Under Review.',
        content:
          'Your documents for bank transfer are still under review. You can still make a deposit via another channel should you wish to start trading now.',
      },
      rejectedInfo: {
        title: 'Rejected',
        content: 'Your request to make a deposit via bank transfer has been rejected.',
      },
      identifyingRequiredInfo: {
        title: 'Additional Info Required',
        content:
          'Some of your identifying documents might be missing or incorrect. Please re-upload them to proceed.',
      },
    },
  },
  propTrade: {
    tags: { new: 'NEW' },
    mainTitle: 'Challenge Dashboard',
    puxtraderlink: 'Go to PU Xtrader',
    end: 'End',
    payout: 'Payout',
    achievementTitle: 'Achievement',
    certificates: 'Certificates',
    howItWorks: {
      title: 'How it works',
      desc: 'Register and prove your trading abilities to qualify for a trading account where you can earn real money trading on a demo account.',
      register: 'Register',
      registerDesc: {
        1: 'Prove your trading abilities and make it to become a PU Trader',
        2: 'Get funded with up to $200,000 in simulated capital, but earn real money from the profits',
        3: 'Get your entire registration fee back',
      },
      trade: 'Trade',
      tradeDesc: {
        1: 'Two risk modes: Basic and Plus (higher risk tolerance and profit target)',
        2: 'Meet the profit targets and avoid loss limits',
        3: 'Trade a minimum of four days',
        4: 'Hit all the requirements to qualify for a PU Trader account',
      },
      getFunded: 'Get Funded',
      getFundedDesc: {
        1: 'Account funded with your Initial Balance chosen at registration',
        2: 'Trade in a simulated “live” environment and get paid 80% of your trading profits',
        3: 'Registration fees returned with your first profit split',
      },
    },
    reminder: {
      title: 'Reminder',
      narrative:
        'We’ll need your Proof of Identity to grant access to your PU Trader account; and your Proof of Address to process your payout. Please submit them via the Authentication tab.',
    },
    button: {
      more: 'More',
      createNewAccount: 'Create New Account',
      viewMetrics: 'View Metric',
      view: 'View',
      backToClientPortal: 'Back to Client Portal',
      submitProceedPayment: 'Proceed to Payment',
      sent: 'Sent',
      back: 'Back',
      proceedPayment: 'Proceed Payment',
      apply: 'Apply',
      joinChallenge: 'Join Challenge',
      join: 'Join',
      payout: 'Payout',
      cancel: 'Cancel',
      changePwd: 'Change Password',
      downloadPdf: 'Download PDF',
      verifyNow: 'Verify Now',
      resetPassword: 'Reset Password',
      history: 'History',
      yes: 'Yes',
    },
    dashboard: {
      accounts: 'Accounts',
      paymentStatus: 'Payment Status',
      completeAuthentication: 'Complete Authentication to access PU Trader',
      accountStatusInfo: {
        1: 'Active : your current session is ongoing and trading is enabled',
        2: 'Verifying : you have hit all your trading goals and your trading history is being assessed',
        3: 'Passed : your trading assessment is successful and we’re preparing your PU Trader account.',
        4: 'Failed : you have hit the loss or time limit and failed this session',
        5: 'Processing : your payment is being processed',
      },
      PaymentStatusInfo: {
        1: 'Success',
        2: 'Failed',
        3: 'Processing (Contact CS if processing time exceeds 3 hours).',
      },
      applicationTime: 'Application time',
      paymentTime: 'Payment time',
      failedTime: 'Failed time',
    },
    resetPassword: {
      currentPwd: 'Current Password',
      currentPwdPlaceholder: 'Please enter current password',
      forgotPwd: 'Forgot Password',
      newPwd: 'New Password',
      newPwdPlaceholder: 'Please enter a new password',
      confirmNewPwd: 'Confirm New Password',
      confirmNewPwdPlaceholder: 'Please confirm new password',
      account: 'Account',
      info: 'Information',
      succ: 'An email has been sent to you to change your password. ',
      succ2:
        'Your password has been updated. You may now use your new password to access your MT4/MT5 trading account.',
      infoText:
        'We have just sent you an email with instructions to reset your password. Please ensure you check your email’s spam folder.',
      newPwdErrorMsg: 'Cannot using Current Password as New Password',
      confirmNewPwdErrorMsg: 'Password is not equal to New Password',
    },
    achievement: {
      noData: 'Pass a Challenge or Evaluation to receive a certificate of recognition.',
      certificate: 'This certificate is presented to',
      description:
        'For successfully passing the {stage}. They have demonstrated considerable discipline and skill trading, and are now eligible to be evaluated as a proprietary trader.',
    },
    selection: { newest: 'Newest First', oldest: 'Oldest First' },
    statusContext: {
      allStatus: 'All Status',
      active: 'Active',
      verifying: 'Verifying',
      passed: 'Passed',
      failed: 'Failed',
      submitted: 'Submitted',
      processing: 'Processing',
      payoutPending: 'Payout Pending',
    },
    accountType: { basic: 'Basic', plus: 'Plus' },
    accountStageContext: {
      allStages: 'All Stages',
      liveTrading: 'PU Trader',
      liveTradingPlus: 'PU Trader+',
      evaluation: 'Evaluation',
      evaluationPlus: 'Evaluation+',
      challenge: 'Challenge',
      challengePlus: 'Challenge+',
    },
    stageContext: { challenge: 'Challenge', evaluation: 'Evaluation', liveTrading: 'PU Trader' },
    sessionContext: {
      all: 'All Status',
      submitted: 'Submitted',
      expired: 'Expired',
      mismatch: 'Mismatch',
      success: 'Success',
      fail: 'Fail',
      Processing: 'Processing',
    },
    history: {
      title: 'History',
      sessionNumber: 'Session Number',
      field: { date: 'Date', event: 'Event' },
      status: {
        0: 'You have met all the trading requirements! You are now eligible for the next stage.',
        1: 'Trading Goals are not met. You may try again.',
        2: 'Congratulations! You have met all the trading requirements for the Evaluation stage. Our team will get in touch soon regarding the PU Xtrader program.',
        3: 'We are processing your profit split . This may take up to 3 working days.',
        4: 'Your Profit split is ready for withdrawal. Please proceed to payout.',
        refunded: 'Fee Refunded & Profit Splitted',
        liveAccountCreated: 'Live Account Created (Trading Account No: {account})',
        verifyAccount: 'Verification Account {account} Passed',
        challangeAccountPassed: 'Challenge Account {account} Passed',
        challangeAccountCreated: 'Challenge Account {account} Created',
        paymentSuccess: 'Payment Success',
      },
      historyEvent: {
        0: 'Payment Success',
        1: 'Payment Fail',
        2: '{accountStage} Account {accountNumber} Created',
        3: '{accountStage} Account {accountNumber} Failed',
        4: '{accountStage} Account {accountNumber} Passed',
        5: '{accountStage} Account {accountNumber} Verifying',
        6: 'Fee Refunded ({currency}{amount})',
        7: 'Fee Refunded Failed',
        8: 'Profit Split ({currency}{amount})',
        9: 'Profit Split Failed',
        10: 'Your PU Trader is ready to trade again!',
        11: 'Payout requested. PU Trader set to read only.',
      },
    },
    metricsPage: {
      mainTitle: 'Metrics',
      mainDesc:
        'Join the PU Xtrader Challenge and qualify to be PU Trader to start earning real money from your simulated trading profits',
      equity: 'Equity',
      infoContext:
        'Equity includes the P&L of all open and closed positions. This value is refreshed every 10 minutes, check your trading platform for the live value.',
      initBalance: 'Initial balance',
      endDate: 'End Date',
      accountNo: 'Account No',
      topInfoField: {
        leverage: 'Leverage',
        server: 'Server',
        platform: 'Platform',
        status: 'Status',
        accountType: 'Account Type',
        startDate: 'Start Date',
      },
      tradingGoals: 'Trading Goals',
      tradingGoalsDesc: 'Track your progress for this account',
      goals: 'Goals',
      tradingGoalsFiels: {
        minTradingDay: 'Minimum {minTradingDay} Trading Days',
        maxDailyLoss: 'Max Daily Lost not more than {maxDailyLoss}',
        maxLoss: 'Max Loss not more than {maxTotalLoss}',
        profitTarget: 'Profit Target {profitTarget}',
      },
      chart: {
        title: 'Current Performance',
        markLine: { profit: 'Profit', maxLoss: 'Max Loss' },
        tooltip: {
          date: 'Date',
          equity: 'Equity',
          trades: 'Number of trades',
          maxDailyLoss: 'Max Daily Loss',
        },
        toolbox: {
          zoom: 'Zoom',
          zoomReset: 'Zoom Reset',
          refresh: 'Refresh',
          saveImage: 'Save Image',
        },
      },
      download: 'Download',
    },
    createAccount: {
      noBalance: 'Unable to create new PT account. Total session balance cannot exceed 3,000,000.',
      narrative:
        'Join PU Xtrader and start trading on your simulated account to qualify for a PU Trader account.',
      chooseAccount: {
        title: 'Test Your Trading Mettle with The PU Xtrader Challenge',
        subTitle: 'Choose Account',
        desc: 'Complete a few steps to open an account and start Challenge.',
        tradingPlatform: 'Trading Platform',
        accountCurrency: 'Choose Account Currency',
        accountType: 'Choose Account Type',
        accountTypeInfo:
          'Plus accounts offer a larger maximum loss limit, but have higher profit targets and fees.',
        tradingGoals: 'Trading Goals',
        maxDailyLoss: 'Max Daily Loss',
        maxLoss: 'Max Loss',
        targetProfit: 'Trading Goals',
        accountBalance: 'Account Balance',
        leverage: 'Leverage',
        leverageInfo:
          '\n          <div>\n              <div>FX - 100:1</div>\n              <div>XAU - 30:1</div>\n              <div>XAG, Oil, Indices - 50:1</div>\n              <div>US Shares - 10:1</div>\n              <div>Crypto - 3:1</div>\n          </div>\n        ',
        agreeTermsAndConditions:
          'I agree to <a href="{link}" target="_blank" rel="">PU Xtrader Terms and Conditions</a>',
        agreeCancellationAndRefundPolicy:
          'I agree to <a href="{link}" target="_blank" rel="">PU Xtrader Agreement</a>',
        agreeTermsAndConditionsDesc:
          'By accepting the PU Xtrader Terms and Conditions, you explicitly acknowledge that your consent pertains solely and exclusively to our PU Xtrader services. Any other features and services that are not related to PU Xtrader are governed by a separate set of terms and conditions. If you wish to access other services offered by PU Prime, you must complete a separate registration.',
        openInstantAccount: 'Open instant PUXtrader account. No evaluation stage needed.',
      },
      billingDetail: {
        title: 'Billing Detail',
        subTitle: 'Payment Method',
        creditcard: 'Credit card',
        crypto: 'Crypto',
        bitcoin: 'Bitcoin',
        usdterc20: 'USDT-ERC20',
        usdttrc20: 'USDT-TRC20',
        eth: 'ETH',
        usdcerc20: 'USDC-ERC20',
        ewallet: 'E-wallet',
        perfectMoney: 'Perfect Money',
        fasapay: 'FasaPay',
        alipay: 'Alipay',
        mobilePay: 'MobilePay',
        sticpay: 'SticPay',
        item: 'Item',
        accountType: 'Account Type',
        accountBalance: 'Account Balance',
        targetProfit: 'Trading Platform',
        promoCode: 'Promo code',
        cancelPromoCodeInfo: 'Are you sure you want to cancel Promo code?     ',
      },
      billingCreditCard: {
        title: 'Billing Detail',
        description:
          'Join the PU Xtrader and start trading on your simulated account to qualify for a funded live account',
        subTitle: '',
      },
      payment: {
        payment: 'Payment',
        totalRegistrationFee: 'Total Registration Fee',
        totalRegistrationFeeInfo:
          'Your registration fee is calculated based on your chosen initial capital, converted to your chosen account currency.',
        paymentDetails: 'Payment Details',
        subtotal: 'Subtotal',
        discount: 'Discount',
        grandTotalRegistrationFee: 'Grand Total Registration Fee',
      },
      cryptoQrcode: { title: 'Payment Details' },
    },
    banner: {
      title:
        '<div class="title">Begin Your Journey<br/>To A Funded Account</div>\n      <div class="des">Trade with our company\'s capital and get 80% of the profits.</div>',
      dashboardBannerTitle: 'Begin Your Journey To A Funded Account',
    },
    promoCode: {
      unknownError: 'Unknown Error',
      validPromoCode: 'Please enter a valid promo code',
      applyFirst: 'Apply Promo Code First',
    },
    payoutPage: {
      submit: 'Submit',
      refundChannel: 'Refund Channel',
      paymentChannel: 'Payment Channel',
      summary: 'Summary',
      refund: 'Refund',
      profitSplit: 'Profit Split',
      sessionNumber: 'Session Number',
      currency: 'Currency',
      profitsplit: '{percent} profit split',
      channelCharges: '{channel} Charges',
      registrationFeeRefund: 'Registration Fee Refund',
      remark: 'Remark',
      totalEligiblePayoutAmount: 'Total Eligible<br />Payout Amount :',
      totalEligiblePayoutAmountInfo:
        'The amount displayed above is the fee for the challenge. If applicable, this amount will be credited back to the original credit card used for payment.',
      profitSplitFee: '{channel} Charges',
      refundAmountFee: 'Refund charges fee',
      payoutSuccessDialog: 'Your payout request is being processed.',
      howToProcessYourProfitSplit: 'How to Process Your Profit Split',
      howToProcessYourProfitSplitDesc:
        'The following steps have to be completed to process the payout from your profit split :',
      howToProcessYourProfitSplitDesc2: '1. Ensure that your account equity is in profit.',
      howToProcessYourProfitSplitDesc3: '2. Close all open positions.',
      howToProcessYourProfitSplitDesc4:
        'Please note that your account will be set as read-only once you click submit. You can continue trading after your payout has been processed and you have been paid.',
      howToProcessYourProfitSplitDesc5:
        'If you do not want to withdraw your profits at this point, click cancel.',
      poaDialog: {
        title: 'Proof of Address Required',
        message:
          'Your Proof of Address needs to be verified before we can process your first payout. Please submit it at least 3 days before this cycle’s profit split date.',
        verifyNow: 'Verify Now',
      },
      payoutRequestIsUnsuccessful: 'Payout Request is Unsuccessful',
      payoutRequestIsUnsuccessfulDesc:
        'Payout request is unsuccessful. Please ensures the following before payout request :',
      payoutRequestIsUnsuccessfulDesc2: '1. Live Account has positive equity',
      payoutRequestIsUnsuccessfulDesc3: '2. Close all open position(s)',
    },
    notification: {
      profitSplit: {
        title: 'Next Profit Split Date',
        context:
          'The next profit split will be on {date}. Please remember close your all trades before it.',
      },
    },
  },
  autochartist: {
    1: {
      title: 'Market Scanner',
      desc01:
        'The world’s first Market Scanner that allows you to scan your market watchlist for trading opportunities.',
      button01: 'Download Now',
    },
    2: {
      title: 'Market Report',
      desc01:
        'Autochartist Market Reports provide you with a snapshot of the technical outlook of the markets before the opening of the major financial centres.',
      button01: 'View Market Reports',
    },
    3: {
      title: 'Autochartist Research Portal',
      desc01:
        'A fully comprehensive research portal that allows you to find all the available data in one place.',
      button01: 'Launch',
      button02: 'User Guide',
      button03: 'OTP Access',
    },
    4: {
      title: 'Trading Opportunities',
      desc01:
        'Check updates on trading opportunities every 15 minutes with new setups based on all Autochartist’s analysis types.',
      button01: 'View Trading Opportunities',
    },
    5: {
      title: 'Autochartist Mobile App',
      desc01:
        'Receive push notifications and track high probability filtered trading opportunities based on your watchlist within the trading platform.',
      desc02: 'Notes:',
      desc03:
        'The QR Code can be scanned from the normal camera, provided the user’s phone has a built in QR scanner, or from inside the autochartist mobile application, by clicking the “Login using QR Code” button.',
      desc04:
        'The QR code is valid for 10 minutes only,after that the image must be refreshed for a new QR code.',
      desc05:
        'The QR code is valid for single use only,after that the image must be refreshed for a new QR code.',
      button01: 'Download For IOS',
      button02: 'Download For Andriod',
    },
    6: {
      title: 'Newsletter Subscription',
      desc01:
        'By subscribing our newsletter, you’ll receive daily emails powered by Autochartist to give you a snapshot of global forex markets. Trade with PU Prime, trade with the world.',
      button01: 'Subscribe',
    },
    footer:
      'Autochartist is the world’s most advanced tool for the automatic identification of trade opportunities in the form of Chart patterns, Fibonacci patterns, and Key Level. Autochartist’s proprietary allows their search engines to monitor thousands of financial instruments 24 hours per day uncovering trade opportunities as they occur. In addition, Autochartist also offers Volatility Analysis as a feature which will give you a better assessment of risk and volatility on the instruments that you trade.',
  },
  responseMsg: {
    410: 'Parameter validation failed',
    411: 'Incorrect password',
    420: 'User does not exist',
    421: 'Password reset denied',
    422: 'Registration error!',
    427: 'Your password has expired. Please reset password to proceed.',
    428: 'Does not match with new password',
    429: 'Please change to a new password',
    434: 'Current password is incorrect',
    451: 'Your operation is too frequent please try again after 60 seconds',
    490: 'Please do not submit duplicate card information',
    500: 'An error has occured.',
    501: 'Application failed. Please try again later.',
    505: 'File upload failed',
    520: 'Login Failed. Please try again.',
    521: 'A user is already logged in on this device!',
    522: 'Please log in again',
    523: 'Please log in again',
    524: 'Unfortunately your email address was not recognized',
    525: 'Incorrect username or password',
    527: 'User is not an IB',
    528: 'User does not exist',
    529: 'Incorrect username or password',
    530: 'Login token invalid',
    540: "Your trading account couldn't be found",
    541: 'Cannot find rebate account',
    542: 'You are not allowed to open additional account',
    544: 'You are not allowed to apply for an additional account until your Proof of ID verified',
    550: 'Incorrect account number',
    551: 'Your balance is insufficient',
    553: 'You cannot withdraw/transfer due to the credit in your account. Please contact {email} for further help',
    554: 'Requested amount is zero or null',
    562: 'We were unable to process your payment, please try again. If the problem persists, please contact us via live chat, or email {email}',
    564: 'Country does not match the payment channel',
    565: 'Incorrect Bank Code',
    566: 'Payment method not found',
    568: 'This account has been restricted from making a deposit, please contact info@puprime.com',
    580: 'Withdrawal or Transfer is restricted. Please contact the support team.',
    581: 'You have successfully opted in to the promotion. Please note that you must make a deposit in order to withdraw funds.',
    582: 'Since you have entered the campaign and have open positions, we are unable to process your withdrawal request.',
    584: "You don't have any applicable rebates",
    587: 'Insufficient Balance please contact the customer service.',
    590: 'Cannot find transfer-out account',
    591: 'Cannot find transfer-in account',
    593: 'We are unable to process your funds transfer from the account selected due to your account containing credit.',
    594: 'Transfer of funds from this account is not allowed',
    595: 'Since you have entered our campaign, we are unable to process your transfer request',
    624: 'This account is not eligible for negative balance reset. Kindly contact our Customer Care team for more information.',
    625: 'Your application is in progress. Kindly contact our Customer Care team for more information.',
    626: 'Your balance is now equals to or larger than zero, please refresh the page.',
    647: 'Invalid verification code',
    651: 'Please note internal transfer is not available over weekends for accounts that have open positions. Please submit your request during weekdays.',
    1001: 'Your account is temporarily unavailable, please contact your account manager',
    1103: 'Account has been disabled',
    1202: 'Verification expired, please try again',
    1203: 'Error Password Validation',
    1204: 'Please try with another email address',
    1205: 'This verification mode switch does not allow operation',
    1206: 'operate illegal',
    1207: 'totp not allow repeat enable',
    1208: 'Verification codes cannot be sent repeatedly',
    1209: 'sendEmailVerificationCode error',
    1210: 'Your password must be between 8-16 characters and a mix of letters (a-z and A-Z), numbers(0-9), and special characters like !@#$%^&.()',
    1211: 'Public Key does not exist in the request header',
    1212: 'Failed to decrypt message',
    1501: 'Invalid parameter',
    1502: 'Invalid promo code',
    1503: 'You are not eligible to use this promo code',
    1504: 'Promo code has been used',
    1505: 'Promo code has exceeded max limit.',
    1508: 'This promo code is only available for new user.',
    1509: 'This promo code is not available for your country.',
    1510: 'This promo code is not yet effective.',
    1511: 'Payout is restricted. Please contact the customer support.',
    session_timeout: 'Session timeout, redirecting to login page.',
    vload: {
      4019: 'Voucher Type or value is not accepted',
      4071: 'Fraud suspicion',
      4072: 'Voucher is used',
      4073: 'Voucher is not active',
      4074: 'Voucher is expired',
      4075: 'Voucher is not found',
      4080: 'Voucher has insufficient value',
      4444: 'Invalid voucher used. Currency mismatch.',
      5000: 'Store data is not available',
    },
  },
  copyTradingBanner: {
    header: 'Sync & Trade With PU Prime',
    desc: 'Let The Experts Do The Work While You Learn.',
    download: 'Download Now',
  },
};
